import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServices, resetServices } from '../slices/serviceSlice';
import { Link } from 'react-router-dom';
import Loader from './Loader'; // Import the Loader component
import { useInView } from 'react-intersection-observer';
import '../CSS/Service.css';

const ServiceCard = ({ service }) => {
  const [cardRef, cardInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      ref={cardRef}
      className={`service-card ${cardInView ? 'fade-in' : ''}`}
    >
      <img
        src={service.front_image}
        alt={service.heading}
        className="service-image"
      />
      <h3>{service.heading}</h3>
      <p>{service.one_line_description}</p>
      <Link to={`/services/${service.id}`} className="read-more">
        Read More <i className="fa-solid fa-arrow-right"></i>
      </Link>
    </div>
  );
};

const Services = () => {
  const dispatch = useDispatch();
  const { services, servicesStatus, currentPage, hasMore, error } = useSelector(
    (state) => state.service
  );

  const { ref, inView } = useInView();

  // **New**: Use useInView for services-section
  const [sectionRef, sectionInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (servicesStatus === 'idle') {
      dispatch(fetchServices({ page: 1, pageSize: 10 }));
    }
  }, [servicesStatus, dispatch]);

  useEffect(() => {
    if (inView && hasMore && servicesStatus !== 'loading') {
      dispatch(fetchServices({ page: currentPage, pageSize: 10 }));
    }
  }, [inView, hasMore, servicesStatus, dispatch, currentPage]);

  useEffect(() => {
    return () => {
      dispatch(resetServices());
    };
  }, [dispatch]);

  if (servicesStatus === 'loading' && services.length === 0) {
    return <Loader />;
  }

  if (servicesStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="Services">
      {/* Apply ref and animation class to services-section */}
      <div
        ref={sectionRef}
        className={`services-section ${sectionInView ? 'slide-in' : ''}`}
      >
        <div className="services-headings">
          <h3 className="services-subtitle">Services</h3>
          <h1 className="services-title">Types Of Care</h1>
        </div>
        <p className="services-description">
          Discover a comprehensive range of medical services tailored to your
          needs. Our expert team provides top-notch healthcare solutions, from
          diagnostics to treatment, ensuring compassionate care and
          state-of-the-art facilities for your optimal health and well-being.
        </p>
      </div>
      <div className="services-cards">
        {services.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
      {servicesStatus === 'loading' && services.length > 0 && <Loader />}
      <div ref={ref} style={{ height: '1px' }}></div>
    </div>
  );
};

export default Services;
