// src/Banner.js
import React, { useRef, useState } from "react";
import "../CSS/Banner.css";
// Removed AOS imports
// import AOS from "aos";
// import "aos/dist/aos.css";

// Import slider components
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useInView } from "react-intersection-observer";

const Banner = () => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0); // Track current slide index

  // Removed AOS initialization
  // useEffect(() => {
  //   AOS.init({
  //     duration: 900, // Animation duration in milliseconds
  //     once: true, // Animation should happen only once
  //   });
  // }, []);

  // Handle slide change
  const handleAfterChange = (current) => {
    setCurrentSlide(current); // Update current slide index
    // Removed AOS.refresh()
  };

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000, // 7 seconds
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false, // Continue autoplay on hover
    arrows: false, // Hide arrows if not needed
    afterChange: handleAfterChange,
  };

  // Array of banner images
  const bannerImages = [
    "/static/banner/banner.jpg",
    "/static/banner/reviews.webp",
    "/static/banner/banner1.webp",
  ];

  return (
    <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        {bannerImages.map((image, index) => (
          <Slide
            key={index}
            image={image}
            index={index}
            currentSlide={currentSlide}
          />
        ))}
      </Slider>

      {/* Boxes Container */}
      <div className="boxes-container">
        <Box
          boxClass="box1"
          iconSrc="/static/icons/doctor.webp"
          altText="Our Services"
          h4Text="Our Services"
          h2Text="Comprehensive Medical Opinions & Tourism"
          delay={0}
        />
        <Box
          boxClass="box2"
          iconSrc="/static/icons/hospital.webp"
          altText="Our Facilities"
          h4Text="Our Facilities"
          h2Text="World-Class Hospitals & Specialist Networks"
          delay={200}
        />
        <Box
          boxClass="box3"
          iconSrc="/static/icons/sethoscope.webp"
          altText="Our Tools"
          h4Text="Our Tools"
          h2Text="Advanced Digital Platforms & Expert Teams"
          delay={400}
        />
      </div>
    </div>
  );
};

const Slide = ({ image, index, currentSlide }) => {
  const [overlayRef, overlayInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div key={index} className="slide">
      <img
        src={image}
        alt={`Banner Slide ${index + 1}`}
        className={`slide-image ${
          currentSlide === index ? "zoom-animation" : ""
        }`}
      />
      {/* Overlay for each slide */}
      <div
        ref={overlayRef}
        className={`overlay ${overlayInView ? "fade-up" : ""}`}
      >
        <div className="overlay-content">
          <h1>Your Trusted Partner in Medical Tourism & Second Opinion</h1>
          <p>
            Access world-class healthcare with personalized second opinions and
            seamless medical travel arrangements.
          </p>
          <button
            onClick={() => {
              /* Add your booking logic here */
            }}
          >
            Book Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

const Box = ({ boxClass, iconSrc, altText, h4Text, h2Text, delay }) => {
  const [boxRef, boxInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div
      ref={boxRef}
      className={`box ${boxClass} ${boxInView ? "fade-up" : ""}`}
      style={{ transitionDelay: `${delay}ms` }}
    >
      <div className="icon-container">
        <img src={iconSrc} alt={altText} />
      </div>
      <div className="text-container">
        <h4>{h4Text}</h4>
        <h2>{h2Text}</h2>
      </div>
    </div>
  );
};

export default Banner;
