import React from 'react'
import Bannersmall from '../Components/Bannersmall'
import Service from '../Components/Service';
function Services() {
    const imageUrl = '/static/banner/services.webp';
  const altText = 'Your Banner Description';
  return (
    <div className='Service-page'>
      <div className='service-banner-text'>
         <Bannersmall imageUrl={imageUrl} altText={altText} />
         <span>Services</span>
         </div>
         <Service />
         
    </div>
  )
}

export default Services