// Calendar.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  ChevronLeft,
  ChevronRight,
  MessageCircle,
  PhoneMissed,
  FileUp,
} from "lucide-react"; // Imported icons for other parts
import Timeline from "./Timeline";
import { useSelector } from "react-redux";

const BookIcon = () => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        width: "80px",
        height: "100px",
        background: "#FF7171",
        borderRadius: "8px",
        position: "absolute",
        top: "33px",
        transform: "rotate(-10deg)",
      }}
    />
    <div
      style={{
        width: "80px",
        height: "100px",
        background: "#4755D9",
        borderRadius: "8px",
        position: "absolute",
        top: "25px",
        left: "10px",
      }}
    />
  </div>
);

// Helper function to format message datetime
const formatMessageDatetime = (messageDatetime) => {
  const messageDate = new Date(messageDatetime);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Reset the time part for accurate comparison
  const isSameDay = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  if (isSameDay(messageDate, today)) {
    // Format time as '10:30 AM'
    return messageDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (isSameDay(messageDate, yesterday)) {
    return "Yesterday";
  } else {
    // Format date as 'Apr 18, 2024'
    return messageDate.toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
};

const Calendar = () => {
  const doctorData = useSelector((state) => state.doctor.doctorData);
  const doctor = doctorData?.doctor;

  // Fallbacks in case of missing data
  const doctorName =
    (doctor?.user?.first_name || "") +
      " " +
      (doctor?.user?.last_name || "") || "Doctor Name";
  const profilePhoto = doctor?.profile_photo || "/static/icons/profile.png";
  const specialization = doctor?.specialization || "Specialization";
  const degree = doctor?.degree || "Degree Not Available";
  const yearsOfExperience = doctor?.years_of_experience || "N/A";

  // Update patient stats
  const totalPatients = doctorData?.totalPatients || 2543; // Replace with actual data
  const pendingPatients = doctorData?.pendingPatients || 8; // Replace with actual data
  const scheduledAppointments = doctorData?.scheduledAppointments || 12; // Replace with actual data

  // Fake messages data
  const messages = [
    {
      id: 1,
      type: "chat",
      name: "Alice Johnson",
      datetime: "2024-04-20T10:30:00", // ISO format
      content: "Hi Doctor, I have a question about my prescription.",
    },
    {
      id: 2,
      type: "missed_call",
      name: "Bob Smith",
      datetime: "2024-04-19T15:45:00",
      content: "Missed your call. Please call back when you can.",
    },
    {
      id: 3,
      type: "file_upload",
      name: "Carol Williams",
      datetime: "2024-04-18T13:15:00",
      content: "Uploaded your lab results.",
      fileName: "lab_results.pdf",
    },
    // Add more fake messages as needed
  ];

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [today] = useState(new Date());
  const [showMonthYearDropdown, setShowMonthYearDropdown] = useState(false);
  const todayRef = useRef(null);

  useEffect(() => {
    if (todayRef.current) {
      todayRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [currentDate]);

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const handleMonthChange = (event) => {
    const [year, month] = event.target.value.split("-");
    setCurrentDate(new Date(year, month - 1, 1));
    setSelectedDate(null); // Clear selection when changing month
    setShowMonthYearDropdown(false);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
    setSelectedDate(null); // Clear selection when changing month
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
    setSelectedDate(null); // Clear selection when changing month
  };

  const renderDaysOfWeek = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return days.map((day) => (
      <div key={day} className="calendar-day-of-week">
        {day}
      </div>
    ));
  };

  const renderDaysAndDates = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).getDay();
    const dates = [];

    // Adjust for Sunday being 0 in getDay()
    const adjustedFirstDay = (firstDayOfMonth + 6) % 7;

    // Add empty cells for days before the first of the month
    for (let i = 0; i < adjustedFirstDay; i++) {
      dates.push(
        <div key={`empty-${i}`} className="calendar-date empty"></div>
      );
    }

    // Add actual days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i
      );
      const isToday = date.toDateString() === today.toDateString();
      const isSelected =
        selectedDate && date.toDateString() === selectedDate.toDateString();

      dates.push(
        <div
          key={i}
          ref={isToday ? todayRef : null}
          className={`calendar-date ${isToday ? "today" : ""} ${
            isSelected ? "selected" : ""
          }`}
          onClick={() => handleDateClick(date)}
        >
          <div className="calendar-number">{i}</div>
        </div>
      );
    }

    return dates;
  };

  const generateMonthOptions = () => {
    const monthOptions = [];
    const currentYear = currentDate.getFullYear();

    for (let year = currentYear - 10; year <= currentYear + 10; year++) {
      for (let month = 0; month < 12; month++) {
        const monthDate = new Date(year, month, 1);
        monthOptions.push(
          <option key={`${year}-${month + 1}`} value={`${year}-${month + 1}`}>
            {monthDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </option>
        );
      }
    }

    return monthOptions;
  };

  const currentDate1 = new Date();
  const day = currentDate1.getDate();
  const month = currentDate1.toLocaleString("en-GB", { month: "long" });
  const year = currentDate1.getFullYear();
  const weekday = currentDate1.toLocaleString("en-GB", { weekday: "long" });

  const formattedDate = `${day} ${month} ${year}, ${weekday}`;

  // Sample data representing people (or patients) with names
  const patients = [
    "Alice",
    "Bob",
    "Charlie",
    "David",
    "Eve",
    "Frank",
    "Grace",
    "Hannah",
  ];

  const getInitials = (name) => name.charAt(0).toUpperCase();

  const maxVisibleAvatars = 3;
  const remainingCount = patients.length - maxVisibleAvatars;

  return (
    <div className="calendar-container">
      <div className="calendar-profile">
        <div className="doctorSidebar2__profile-box">
          <div className="doctorSidebar2__profile-section">
            <div className="doctorSidebar2__avatar">
              <img src={profilePhoto} alt="Doctor" />
            </div>
            <div className="doctorSidebar2__doctor-info">
              <h2>Dr. {doctorName}</h2>
              <span>
                <p>{degree}</p>
                <p>, {specialization}</p>
              </span>
            </div>
            <button className="doc-profile-button">Profile</button>
          </div>
        </div>

        {/* Calendar Box */}
        <div className="calendar-box">
          <div className="calendar-header">
            <button onClick={handlePrevMonth} className="calendar-nav-button">
              <ChevronLeft size={24} />
            </button>
            <div
              className="month-year-display"
              onClick={() => setShowMonthYearDropdown(!showMonthYearDropdown)}
            >
              {currentDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </div>

            <button onClick={handleNextMonth} className="calendar-nav-button">
              <ChevronRight size={24} />
            </button>
          </div>
          {showMonthYearDropdown && (
            <div className="month-dropdown">
              <select
                value={`${currentDate.getFullYear()}-${
                  currentDate.getMonth() + 1
                }`}
                onChange={handleMonthChange}
              >
                {generateMonthOptions()}
              </select>
            </div>
          )}
          <div className="calendar-grid">
            {renderDaysOfWeek()}
            {renderDaysAndDates()}
          </div>
        </div>
        <div className="doctorSidebar2__message-section">
          <span
            className="doctorSidebar2__message_heading"
          >
            <h3>Notifications</h3>
            <span style={{ margin: "0 6px" }}>•</span>
            <p className="doctorSidebar2__unread-count">
              {messages.length === 0
                ? "No new notifications"
                : `${messages.length} New notifications`}
            </p>
          </span>

          <div className="doctorSidebar2__message-list">
            {messages.length === 0 ? (
              <p>No new notifications</p>
            ) : (
              messages.map((message) => {
                let IconComponent;
                let messageTypeClass;

                switch (message.type) {
                  case "chat":
                    IconComponent = MessageCircle;
                    messageTypeClass = "message-chat";
                    break;
                  case "missed_call":
                    IconComponent = PhoneMissed;
                    messageTypeClass = "message-missed_call";
                    break;
                  case "file_upload":
                    IconComponent = FileUp;
                    messageTypeClass = "message-file_upload";
                    break;
                  default:
                    IconComponent = MessageCircle; // Default icon
                    messageTypeClass = "message-chat"; // Default class
                }

                return (
                  <div
                    key={message.id}
                    className="doctorSidebar2__message-item"
                  >
                    <div
                      className={`doctorSidebar2__message-avatar ${messageTypeClass}`}
                    >
                      <IconComponent size={20} /> {/* Adjust size as needed */}
                    </div>
                    <div className="doctorSidebar2__message-content">
                      <div className="doctorSidebar2__message-header">
                        <p className="doctorSidebar2__sender-name">
                          {message.name}
                        </p>
                        <p className="doctorSidebar2__message-datetime">
                          {formatMessageDatetime(message.datetime)}
                        </p>
                      </div>
                      <p className="doctorSidebar2__message-preview">
                        {message.type === "file_upload" ? (
                          <>
                            {message.content}{" "}
                            <a href="#">{message.fileName}</a>
                          </>
                        ) : (
                          message.content
                        )}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* Appointment Updates and Notifications */}
      <div className="appointment-updates">
        <div className="appointment-updates__content">
          <div className="appointment-updates__search-bar">
            <div className="search-bar">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
              <input
                type="search"
                placeholder="Search"
                className="appointment-updates search-input"
              />
            </div>
            <span className="appointment-updates__date-text">{formattedDate}</span>
          </div>

          <div className="appointment-updates__welcome-card">
            <div className="welcome-content">
              <h1 className="title">Welcome back, Dr. {doctorName}!</h1>
              <p className="subtitle">
                Every day is a new opportunity to make a positive difference,
                and together, we can achieve great things!.
                Your next patient appointment is in 15 minutes.
              </p>
              <button className="button">View Schedule</button>
            </div>
            <div className="banner-image">
              <img
                src="/static/banner/doc-banner.png"
                alt="Doctor"
              />
            </div>
            <div className="illustration">
              <BookIcon />
            </div>
          </div>
          <div className="appointment-updates__cards-box">
            <h2 className="appointment-updates__cards-heading">Your Stats</h2>
            <div className="appointment-updates__cards-container">
              {/* Total Patients Card */}
              <div className="appointment-updates__card appointment-updates__card--total-patients">
                <div className="appointment-updates__card-header">
                  <span className="appointment-updates__card-title">Total Patients</span>
                  <div className="appointment-updates__avatar-group">
                    {/* Display initial avatars */}
                    {patients.slice(0, maxVisibleAvatars).map((patient, index) => (
                      <div key={index} className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">
                          {getInitials(patient)}
                        </span>
                      </div>
                    ))}

                    {/* Display remaining count in the last avatar */}
                    {remainingCount > 0 && (
                      <div className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">+{remainingCount}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="appointment-updates__card-info">
                  <span>{totalPatients} registered patients</span>
                </div>
              </div>

              {/* Today's Schedule Card */}
              <div className="appointment-updates__card appointment-updates__card--todays-schedule">
                <div className="appointment-updates__card-header">
                  <span className="appointment-updates__card-title">Today's Schedule</span>
                  <div className="appointment-updates__avatar-group">
                    {/* For demonstration, using first two patients */}
                    {patients.slice(0, 2).map((patient, index) => (
                      <div key={index} className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">
                          {getInitials(patient)}
                        </span>
                      </div>
                    ))}
                    {/* If there are more than 2 appointments */}
                    {scheduledAppointments - 2 > 0 && (
                      <div className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">
                          +{scheduledAppointments - 2}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="appointment-updates__card-info">
                  <span>{scheduledAppointments} appointments</span>
                </div>
              </div>

              {/* Pending Requests Card */}
              <div className="appointment-updates__card appointment-updates__card--pending-requests">
                <div className="appointment-updates__card-header">
                  <span className="appointment-updates__card-title">Pending Requests</span>
                  <div className="appointment-updates__avatar-group">
                    {/* Display first pending patient */}
                    {patients.slice(0, 1).map((patient, index) => (
                      <div key={index} className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">
                          {getInitials(patient)}
                        </span>
                      </div>
                    ))}
                    {/* If there are more pending requests */}
                    {pendingPatients - 1 > 0 && (
                      <div className="appointment-updates__avatar">
                        <span className="appointment-updates__avatar-text">
                          +{pendingPatients - 1}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="appointment-updates__card-info">
                  <span>{pendingPatients} requests pending</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Timeline selectedDate={selectedDate || currentDate} />
      </div>
    </div>
  );
};

export default Calendar;
