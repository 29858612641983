// ChatWindow.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MessageInput from './MessageInput';
import createSocketConnection from '../services/websocket';
import VideoCall from './VideoCall';
import { FiPhone, FiVideo, FiPhoneOff, FiArrowLeft } from 'react-icons/fi'; // Using react-icons for better scalability

const ChatWindowContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  @media (max-width: 768px) {
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #ddd;
  background-color: #f7f7f7;
  
  @media (max-width: 768px) {
    padding: 10px 15px;
    flex-direction: row;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BackButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const CallButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const IconButton = styled.button`
  background: ${(props) => (props.end ? '#e74c3c' : '#3498db')};
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  
  &:hover {
    background: ${(props) => (props.end ? '#c0392b' : '#2980b9')};
  }
  
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 1rem;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.$isCurrentUser ? 'flex-end' : 'flex-start')};
  
  @media (max-width: 768px) {
    justify-content: ${(props) => (props.$isCurrentUser ? 'flex-end' : 'flex-start')};
  }
`;

const MessageBubble = styled.div`
  background-color: ${(props) => (props.$isCurrentUser ? '#DCF8C6' : '#E8E8E8')};
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 1rem;
  
  @media (max-width: 768px) {
    max-width: 80%;
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Video = styled.video`
  width: 80%;
  max-height: 80%;
  
  @media (max-width: 768px) {
    width: 100%;
    max-height: 70%;
  }
`;

const LocalVideo = styled.video`
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 200px;
  height: 150px;
  background: black;
  border: 2px solid white;
  border-radius: 5px;
  
  @media (max-width: 768px) {
    width: 150px;
    height: 112px;
    bottom: 80px;
    right: 10px;
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  
  @media (max-width: 768px) {
    bottom: 10px;
  }
`;

const Button = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  
  &:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    margin: 0 5px;
    padding: 8px 16px;
    font-size: 0.9rem;
  }
`;

const NoAppointments = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 1rem;
  
  @media (max-width: 768px) {
    padding: 15px;
    font-size: 0.9rem;
  }
`;

const ChatWindow = ({ selectedUser, roomName, currentUser, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [callType, setCallType] = useState(null);
  const [isVideoCallActive, setIsVideoCallActive] = useState(false);
  const [isInitiator, setIsInitiator] = useState(false);

  const socket = useRef(null);
  const videoCallRef = useRef(null);
  const signalingMessageQueue = useRef([]); // Added signaling message queue

  useEffect(() => {
    if (!selectedUser || !roomName) return;

    // Close existing WebSocket connection if already present
    if (socket.current) {
      socket.current.close();
    }

    socket.current = createSocketConnection(roomName, currentUser.access_token, handleSocketMessage);

    // Fetch previous messages
    fetchPreviousMessages();

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [roomName, selectedUser, currentUser]);

  const handleSocketMessage = (data) => {
    if (data.type) {
      switch (data.type) {
        case 'chat_message':
          handleIncomingMessage(data);
          break;
        case 'fetch_previous_messages':
          handlePreviousMessages(data.messages);
          break;
        case 'call':
          handleIncomingCall(data);
          break;
        case 'offer':
        case 'answer':
        case 'ice-candidate':
          handleSignalingMessage(data);
          break;
        case 'end-call':
          handleEndCall();
          break;
        default:
          console.log('Unknown message type:', data.type);
      }
    } else {
      handleIncomingMessage({
        sender: data.sender,
        message: data.message,
        roomName: data.roomName,
        timestamp: new Date().toISOString(),
      });
    }
  };

  const getCurrentUserUsername = () => {
    if (currentUser.user && currentUser.user.username) {
      return currentUser.user.username;
    } else if (currentUser.patient && currentUser.patient.user && currentUser.patient.user.username) {
      return currentUser.patient.user.username;
    } else if (currentUser.doctor && currentUser.doctor.user && currentUser.doctor.user.username) {
      return currentUser.doctor.user.username;
    } else {
      return null;
    }
  };

  const getCurrentUserFullName = () => {
    if (currentUser.user && currentUser.user.first_name) {
      return `${currentUser.user.first_name} ${currentUser.user.last_name}`;
    } else if (currentUser.patient && currentUser.patient.user && currentUser.patient.user.first_name) {
      return `${currentUser.patient.user.first_name} ${currentUser.patient.user.last_name}`;
    } else if (currentUser.doctor && currentUser.doctor.user && currentUser.doctor.user.first_name) {
      return `${currentUser.doctor.user.first_name} ${currentUser.doctor.user.last_name}`;
    } else {
      return 'Unknown User';
    }
  };

  const getCurrentUserType = () => {
    if (currentUser.user_type) {
      return currentUser.user_type;
    } else if (currentUser.patient && currentUser.patient.user_type) {
      return currentUser.patient.user_type;
    } else if (currentUser.doctor && currentUser.doctor.user_type) {
      return currentUser.doctor.user_type;
    } else {
      return 'unknown';
    }
  };

  const fetchPreviousMessages = () => {
    socket.current.send({
      type: 'fetch_previous_messages',
      roomName: roomName,
    });
  };

  const handleIncomingMessage = (data) => {
    if (data.sender === getCurrentUserUsername()) {
      return;
    }

    setMessages((prevMessages) => {
      const existingMessage = prevMessages.find(
        (msg) =>
          msg.content === data.message &&
          msg.sender === data.sender &&
          Math.abs(new Date(msg.timestamp).getTime() - new Date(data.timestamp).getTime()) < 1000
      );

      if (existingMessage) {
        return prevMessages;
      }

      console.log('Adding new message:', data);

      return [
        ...prevMessages,
        {
          sender: data.sender,
          content: data.message,
          files: data.files || [],
          timestamp: data.timestamp || new Date().toISOString(),
          isCurrentUser: data.sender === getCurrentUserUsername(),
        },
      ];
    });
  };

  const handlePreviousMessages = (messageArray) => {
    const previousMessages = messageArray.map((msg) => ({
      sender: msg.sender,
      content: msg.message,
      timestamp: msg.timestamp,
      files: msg.files || [],
      isCurrentUser: msg.sender === getCurrentUserUsername(),
    }));

    setMessages(previousMessages);
  };

  const sendMessage = ({ message, files }) => {
    if (message) {
      const newMessage = {
        sender: getCurrentUserUsername(),
        content: message,
        files: files.map((file) => file.name),
        timestamp: new Date().toISOString(),
        isCurrentUser: true,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);

      console.log('Sending message:', newMessage);

      if (socket.current) {
        const messageData = {
          type: 'chat_message',
          message,
          files: files.map((file) => file.name),
          to: selectedUser.user.username,
          senderType: getCurrentUserType(),
          receiverType: selectedUser.user_type,
          roomName: roomName,
        };
        socket.current.send(messageData);
      }
    }
  };

  const startCall = async (type) => {
    setCallType(type);
    setIsInitiator(true);
    setIsVideoCallActive(true);

    socket.current.send({
      type: 'call',
      to: selectedUser.user.username,
      from: getCurrentUserUsername(),
      call_type: type, // Use 'call_type' to match server expectation
    });
  };

  const handleIncomingCall = (data) => {
    if (getCurrentUserUsername() === data.from) {
      return;
    }

    if (window.confirm(`Incoming ${data.call_type} call from ${data.from}. Do you want to accept?`)) {
      setCallType(data.call_type); // Update here as well
      setIsInitiator(false);
      setIsVideoCallActive(true);
    } else {
      socket.current.send({ type: 'end-call', to: data.from });
    }
  };

  // Modified handleSignalingMessage to queue messages if VideoCall is not ready
  const handleSignalingMessage = (data) => {
    if (data.from === getCurrentUserUsername()) {
      return; // Ignore messages from ourselves
    }

    console.log('Received signaling message:', data);

    if (videoCallRef.current && typeof videoCallRef.current.handleSignalingMessage === 'function') {
      videoCallRef.current.handleSignalingMessage(data);
    } else {
      // Queue the message until VideoCall component is ready
      signalingMessageQueue.current.push(data);
    }
  };

  // Process queued signaling messages when VideoCall is ready
  useEffect(() => {
    if (videoCallRef.current && signalingMessageQueue.current.length > 0) {
      signalingMessageQueue.current.forEach((data) => {
        videoCallRef.current.handleSignalingMessage(data);
      });
      signalingMessageQueue.current = [];
    }
  }, [videoCallRef.current]);

  const endCall = () => {
    socket.current.send({
      type: 'end-call',
      to: selectedUser.user.username,
    });
    handleEndCall();
  };

  const handleEndCall = () => {
    setCallType(null);
    setIsVideoCallActive(false);
  };

  return (
    <ChatWindowContainer>
      <Header>
        <UserInfo>
          <BackButton onClick={onBack} aria-label="Back to Chat List">
            <FiArrowLeft />
          </BackButton>
          <UserName>
            {selectedUser.user.first_name} {selectedUser.user.last_name}
          </UserName>
        </UserInfo>
        <CallButtons>
          {!isVideoCallActive && (
            <>
              <IconButton onClick={() => startCall('audio')} aria-label="Start Audio Call">
                <FiPhone />
              </IconButton>
              <IconButton onClick={() => startCall('video')} aria-label="Start Video Call">
                <FiVideo />
              </IconButton>
            </>
          )}
          {isVideoCallActive && (
            <IconButton end onClick={endCall} aria-label="End Call">
              <FiPhoneOff />
            </IconButton>
          )}
        </CallButtons>
      </Header>
      <MessagesContainer>
        {messages.map((msg, index) => (
          <MessageWrapper key={index} $isCurrentUser={msg.isCurrentUser}>
            <MessageBubble $isCurrentUser={msg.isCurrentUser}>
              <strong>
                {msg.isCurrentUser
                  ? getCurrentUserFullName()
                  : `${selectedUser.user.first_name} ${selectedUser.user.last_name}`}
                :{' '}
              </strong>
              {msg.content}
              {msg.files && msg.files.length > 0 && (
                <div>
                  <strong>Files:</strong>
                  <ul>
                    {msg.files.map((file, fileIndex) => (
                      <li key={fileIndex}>{file.name || file}</li>
                    ))}
                  </ul>
                </div>
              )}
            </MessageBubble>
          </MessageWrapper>
        ))}
      </MessagesContainer>
      <MessageInput onSendMessage={sendMessage} />
      {isVideoCallActive && (
        <VideoCall
          ref={videoCallRef}
          socket={socket}
          selectedUser={selectedUser}
          currentUser={currentUser}
          callType={callType}
          onEndCall={handleEndCall}
          isInitiator={isInitiator}
          currentUserUsername={getCurrentUserUsername()} // Pass currentUserUsername
        />
      )}
    </ChatWindowContainer>
  );
};

export default ChatWindow;
