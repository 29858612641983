import React from 'react';
import '../CSS/Terms.css'
import Bannersmall from '../Components/Bannersmall';
const RefundPolicy = () => {
    const imageUrl = '/static/banner/refund.webp';
    const altText = 'Your Banner Description';
    return (
        <div>
            <Bannersmall imageUrl={imageUrl} altText={altText} />
            <div className='terms-page'>
                <div className="terms-content privacy-policy refund-policy">
                <h5>Refund Policy</h5>
      <p>
        At MedBridge Access we are committed to providing our patients with the
        highest level of service. We understand that there may be situations
        where a refund is necessary and we strive to make this process as
        transparent and efficient as possible. This Refund Policy outlines the
        conditions under which refunds will be granted and the procedure for
        processing them.
      </p>

      <h5>1. Eligibility for Refunds</h5>
      <p>Patients are entitled to a full refund under the following circumstances:</p>
      <ul>
        <li>
          <strong>Technical Difficulties:</strong> In the event of any technical
          issues on our end that prevent the scheduled consultation from taking
          place.
        </li>
        <li>
          <strong>Doctor No-Show:</strong> If the appointed doctor fails to
          attend the scheduled consultation without prior notice.
        </li>
        <li>
          <strong>Cancellations by MedBridge Access:</strong> If MedBridge
          Access cancels the appointment for any reason.
        </li>
      </ul>

      <h5>2. Refund Request Process</h5>
      <p>
        If you believe you are entitled to a refund based on the criteria above,
        please contact our Customer Support Team at{" "}
        <a href="mailto:support@medbridgeaccess.com">
          support@medbridgeaccess.com
        </a>{" "}
        within 7 days of the scheduled appointment date.
      </p>
      <p>
        Your refund request should include your name, appointment date, the
        reason for the refund request, and any relevant supporting details.
      </p>

      <h5>3. Processing of Refunds</h5>
      <p>
        Once a refund request is received, we will review it and confirm
        eligibility within 5 working days. If approved, the refund will be
        processed and issued within 14 working days from the date of approval.
      </p>
      <p>
        The refund will be made using the original method of payment, and you
        will receive a confirmation email once the process is completed.
      </p>

      <h5>4. Exclusions</h5>
      <p>
        Refunds will not be granted for cancellations or changes initiated by
        the patient unless otherwise agreed upon by MedBridge Access.
      </p>

      <h5>5. Changes to the Refund Policy</h5>
      <p>
        MedBridge Access reserves the right to modify this Refund Policy at any
        time. Any changes will be communicated via our website or through email
        to affected patients.
      </p>

      <p>
        For any questions or concerns regarding this Refund Policy, please
        contact our Customer Support Team at{" "}
        <a href="mailto:info@medbridgeaccess.com">info@medbridgeaccess.com</a>.
      </p>

      <p>
        By using MedBridge Access services, you agree to the terms of this
        Refund Policy. We are committed to addressing any issues promptly and
        appreciate your understanding.
      </p>
                </div>
                </div>
            </div>
        
    );
};

export default RefundPolicy;
