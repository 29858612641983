import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Mic, MicOff, Camera, CameraOff, PhoneOff } from 'lucide-react';

const VideoCall = forwardRef(
  (
    { socket, selectedUser, currentUser, callType, onEndCall, isInitiator, currentUserUsername },
    ref
  ) => {
    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [isCameraOff, setIsCameraOff] = useState(callType === 'audio');

    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const peerConnectionRef = useRef(null);
    const iceCandidatesQueue = useRef([]);
    const signalingMessageQueue = useRef([]); // Queue signaling messages until ready

    const log = (message, level = 'info') => {
      if (process.env.NODE_ENV !== 'production' || level === 'error') {
        console[level](`[VideoCall] ${message}`);
      }
    };

    useEffect(() => {
      createPeerConnection(); // Initialize PeerConnection first
      initializeCall(); // Then get local media stream and add tracks
      return () => {
        cleanupCall();
      };
    }, []);

    // Handle signaling messages from ChatWindow
    useImperativeHandle(ref, () => ({
      handleSignalingMessage: (data) => {
        if (!peerConnectionRef.current || !localStream) {
          signalingMessageQueue.current.push(data);
        } else {
          processSignalingMessage(data);
        }
      },
    }));

    // Process queued signaling messages once PeerConnection and localStream are ready
    useEffect(() => {
      if (peerConnectionRef.current && localStream && signalingMessageQueue.current.length > 0) {
        signalingMessageQueue.current.forEach((data) => processSignalingMessage(data));
        signalingMessageQueue.current = [];
      }
    }, [peerConnectionRef.current, localStream]);

    const processSignalingMessage = (data) => {
      log(`Received ${data.type} message`);
      console.log('Signaling message received:', data); // Debugging
      switch (data.type) {
        case 'offer':
          handleOffer(data.offer);
          break;
        case 'answer':
          handleAnswer(data.answer);
          break;
        case 'ice-candidate':
          handleICECandidate(data.candidate);
          break;
        default:
          log(`Unknown message type: ${data.type}`, 'warn');
      }
    };

    const initializeCall = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: callType === 'video',
        });
        console.log('Local stream tracks:', stream.getTracks());
        setLocalStream(stream);

        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }

        // Add tracks to the already initialized PeerConnection
        stream.getTracks().forEach((track) => {
          peerConnectionRef.current.addTrack(track, stream);
          console.log('Added track:', track); // Debugging
        });

        if (isInitiator) {
          createOffer();
        }
      } catch (error) {
        log(`Error initializing call: ${error.message}`, 'error');
        alert(`Error accessing camera/microphone: ${error.message}`);
      }
    };

    const createPeerConnection = () => {
      const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
      const peerConnection = new RTCPeerConnection(configuration);
      peerConnectionRef.current = peerConnection;

      // Do not add tracks here; they will be added after local stream is obtained

      peerConnection.ontrack = (event) => {
        log('Received remote stream');
        console.log('Remote stream tracks:', event.streams[0].getTracks()); // Debugging
        setRemoteStream(event.streams[0]);
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = event.streams[0];
        }
      };

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          console.log('ICE candidate gathered:', event.candidate);
          sendSignalingMessage('ice-candidate', { candidate: event.candidate });
        } else {
          console.log('All ICE candidates have been gathered');
        }
      };

      peerConnection.oniceconnectionstatechange = () => {
        console.log(`ICE connection state: ${peerConnectionRef.current.iceConnectionState}`);
        log(`ICE connection state: ${peerConnectionRef.current.iceConnectionState}`);
      };

      peerConnection.onsignalingstatechange = () => {
        console.log(`Signaling state changed: ${peerConnectionRef.current.signalingState}`);
      };
    };

    const createOffer = async () => {
      if (!peerConnectionRef.current) {
        log('PeerConnection not initialized', 'error');
        return;
      }

      // Only create an offer if the signaling state is 'stable'
      if (peerConnectionRef.current.signalingState !== 'stable') {
        log('Cannot create offer in signaling state ' + peerConnectionRef.current.signalingState);
        return;
      }

      try {
        const offer = await peerConnectionRef.current.createOffer();
        await peerConnectionRef.current.setLocalDescription(offer);
        sendSignalingMessage('offer', { offer });
        console.log('Created offer:', offer); // Debugging
      } catch (error) {
        log(`Error creating offer: ${error.message}`, 'error');
      }
    };

    const handleOffer = async (offer) => {
      if (!peerConnectionRef.current) {
        log('PeerConnection not initialized when receiving offer', 'error');
        return;
      }

      if (peerConnectionRef.current.signalingState !== 'stable') {
        log('Cannot handle offer in signaling state ' + peerConnectionRef.current.signalingState);
        return;
      }

      try {
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        processIceCandidateQueue();
        const answer = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(answer);
        sendSignalingMessage('answer', { answer });
        console.log('Created answer:', answer);
      } catch (error) {
        log(`Error handling offer: ${error.message}`, 'error');
      }
    };

    const handleAnswer = async (answer) => {
      if (!peerConnectionRef.current) {
        log('PeerConnection not initialized when receiving answer', 'error');
        return;
      }

      // Only handle the answer if the signaling state is 'have-local-offer'
      if (peerConnectionRef.current.signalingState !== 'have-local-offer') {
        log('Cannot handle answer in signaling state ' + peerConnectionRef.current.signalingState);
        return;
      }

      try {
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
        processIceCandidateQueue();
        console.log('Answer set as remote description:', answer); // Debugging
      } catch (error) {
        log(`Error handling answer: ${error.message}`, 'error');
      }
    };

    const handleICECandidate = (candidate) => {
      console.log('ICE candidate received:', candidate);
      if (peerConnectionRef.current && peerConnectionRef.current.remoteDescription) {
        peerConnectionRef.current
          .addIceCandidate(new RTCIceCandidate(candidate))
          .then(() => console.log('ICE candidate added successfully'))
          .catch((error) => console.error(`Error adding ICE candidate: ${error.message}`));
      } else {
        iceCandidatesQueue.current.push(candidate);
      }
    };

    const processIceCandidateQueue = () => {
      if (peerConnectionRef.current && peerConnectionRef.current.remoteDescription) {
        iceCandidatesQueue.current.forEach((candidate) => {
          peerConnectionRef.current
            .addIceCandidate(new RTCIceCandidate(candidate))
            .then(() => console.log('Added queued ICE candidate:', candidate))
            .catch((error) => console.error(`Error adding queued ICE candidate: ${error.message}`));
        });
        iceCandidatesQueue.current = [];
      }
    };

    // Update sendSignalingMessage to include 'from' field
    const sendSignalingMessage = (type, payload) => {
      if (socket.current) {
        console.log(`Sending signaling message: ${type}`, payload); // Debugging
        socket.current.send({
          type,
          ...payload,
          to: selectedUser.user.username,
          from: currentUserUsername, // Include 'from' field
        });
      }
    };

    const toggleMute = () => {
      if (localStream) {
        const audioTracks = localStream.getAudioTracks();
        audioTracks.forEach((track) => {
          track.enabled = !track.enabled;
        });
        setIsMuted(!audioTracks[0].enabled);
      }
    };

    const toggleCamera = () => {
      if (localStream) {
        const videoTracks = localStream.getVideoTracks();
        videoTracks.forEach((track) => {
          track.enabled = !track.enabled;
        });
        setIsCameraOff(!videoTracks[0].enabled);
      }
    };

    const cleanupCall = () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
    };

    const endCall = () => {
      cleanupCall();
      onEndCall();
    };

    return (
      <div className="video-call-container">
        <div className="remote-video-wrapper">
          <video ref={remoteVideoRef} autoPlay playsInline className="remote-video" />
        </div>
        <div className="local-video-wrapper">
          <video ref={localVideoRef} autoPlay playsInline muted className="local-video" />
        </div>
        <div className="controls">
          <button onClick={toggleMute} className="control-button">
            {isMuted ? <MicOff size={24} /> : <Mic size={24} />}
          </button>
          <button onClick={toggleCamera} className="control-button">
            {isCameraOff ? <CameraOff size={24} /> : <Camera size={24} />}
          </button>
          <button onClick={endCall} className="control-button end-call">
            <PhoneOff size={24} />
          </button>
        </div>

        <style>{`
         .video-call-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .remote-video-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .remote-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .local-video-wrapper {
      position: absolute;
      bottom: 80px;
      right: 20px;
      width: 200px;
      height: 150px;
      border: 2px solid #fff;
      border-radius: 8px;
      overflow: hidden;
    }
    .local-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .controls {
      position: absolute;
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .control-button {
      margin: 0 10px;
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s;
    }
    .control-button:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .end-call {
      background-color: rgba(255, 0, 0, 0.6);
    }
    .end-call:hover {
      background-color: rgba(255, 0, 0, 0.8);
    }
      `}</style>
      </div>
    );
  });

export default VideoCall;
