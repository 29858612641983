import React from 'react'
import '../CSS/DashboardContent.css'
import Calendar from './Calendar'
import DoctorStats from './DoctorStats';
function DashboardContent() {
    return (
        <div className="dashboard-doctor">    
          <div className="dashboard-body">
            <div className="calendar-section">
            <div className="dashboard-banner">
            <div className='dashboard-banner-overlay'>

            </div>
            
          </div>
          

              <Calendar />
            </div>
    
            
          </div>
        </div>
      );
}

export default DashboardContent