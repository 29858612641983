import React from 'react';
import '../CSS/MedicalProcess.css'; // Updated CSS file name
import { InView } from 'react-intersection-observer';

const MedicalProcess = () => {
  const steps = [
    {
      title: 'Explore',
      description: 'Find and research top hospitals & destinations',
      icon: (
        <img
          src="/static/icons/search.webp"
          alt="Explore"
          className="medical-icon-img"
        />
      ),
    },
    {
      title: 'Quote',
      description: 'Request a quote directly through our website',
      icon: (
        <img
          src="/static/icons/medical-invoice.webp"
          alt="Quote"
          className="medical-icon-img"
        />
      ),
    },
    {
      title: 'Match',
      description:
        'Based on your criteria, we recommend the best hospitals or providers',
      icon: (
        <img
          src="/static/icons/balanced.webp"
          alt="Match"
          className="medical-icon-img"
        />
      ),
    },
    {
      title: 'Arrange',
      description:
        'The hospital/provider will contact you directly to coordinate your treatment',
      icon: (
        <img
          src="/static/icons/networking.webp"
          alt="Arrange"
          className="medical-icon-img"
        />
      ),
    },
    {
      title: 'Treat',
      description:
        'Finalize your treatment with the hospital/provider of your choice',
      icon: (
        <img
          src="/static/icons/first-aid-kit.webp"
          alt="Treat"
          className="medical-icon-img"
        />
      ),
    },
  ];

  return (
    <div className="medical-process">
      <h2 className="process-heading">
        The Simplest Process in Finding Healthcare Abroad
      </h2>
      <p className="process-subheading">
        We'll Help You Map Out Your Medical Tourism Journey
      </p>
      <div className="medical-steps">
        {steps.map((step, index) => (
          <InView key={index} threshold={0.1} triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className={`medical-step ${inView ? 'animate' : ''}`}
                style={{ transitionDelay: `${index * 200}ms` }}
              >
                <div className="medical-icon">{step.icon}</div>
                <h3 className="medical-title">{step.title}</h3>
                <p className="medical-description">{step.description}</p>
              </div>
            )}
          </InView>
        ))}
      </div>
      <button className="medical-quote-button">
        Request a Quote for Healthcare Now
      </button>
    </div>
  );
};

export default MedicalProcess;
