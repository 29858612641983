import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Step 1: Send OTP during doctor creation
export const sendOtp = createAsyncThunk(
  'doctor/sendOtp',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/create_doctor/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // OTP sent successfully
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        // Backend returned an error response
        return rejectWithValue(error.response.data);
      } else {
        // Network or other error
        return rejectWithValue({
          errors: [
            {
              code: 'NETWORK_ERROR',
              message: 'Network error occurred. Please try again later.',
            },
          ],
        });
      }
    }
  }
);

// **New Action:** Resend OTP
export const resendOtp = createAsyncThunk(
  'doctor/resendOtp',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/resend_otp/', { email });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          errors: [
            {
              code: 'NETWORK_ERROR',
              message: 'Network error occurred. Please try again later.',
            },
          ],
        });
      }
    }
  }
);


// Step 2: Verify OTP
export const verifyOtp = createAsyncThunk(
  'doctor/verifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/verify_otp/', { email, otp });
      // Store the complete doctor data
      localStorage.setItem('doctorData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          errors: [
            {
              code: 'NETWORK_ERROR',
              message: 'Network error occurred. Please try again later.',
            },
          ],
        });
      }
    }
  }
);


export const loginDoctor = createAsyncThunk(
  'doctor/loginDoctor',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/doctor_login/', credentials);
      localStorage.setItem('doctorData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      // Ensure we are passing the error in the correct format
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data); // Return backend errors
      } else {
        return rejectWithValue({
          errors: [
            {
              code: 'NETWORK_ERROR',
              message: 'Network error occurred. Please try again later.',
            },
          ],
        });
      }
    }
  }
);


export const fetchDoctorAppointmentsByDate = createAsyncThunk(
  'doctor/fetchDoctorAppointmentsByDate',
  async (selectedDate, { getState, rejectWithValue }) => {
    try {
      const { doctorData } = getState().doctor;
      const token = doctorData?.access_token;
      const date = selectedDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      console.log(date);
      const response = await axios.get(`/api/doctor/appointments/?date=${date}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('appointments',response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'doctor/requestPasswordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_request/', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyResetOtp = createAsyncThunk(
  'doctor/verifyResetOtp',
  async ({ email, reset_token }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', { email, reset_token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'doctor/resetPassword',
  async ({ email, reset_token, new_password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', {
        email,
        reset_token,
        new_password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


const doctorSlice = createSlice({
  name: 'doctor',
  initialState: {
    doctorData: JSON.parse(localStorage.getItem('doctorData')) || null,
    status: 'idle',
    error: null,
    otpStatus: 'idle',
    otpError: null,
    resendOtpStatus: 'idle',
    resendOtpError: null,
    otpSent: false,
    appointments: [],
    passwordResetStatus: 'idle',
    passwordResetError: null,
    otpVerificationStatus: 'idle',
    otpVerificationError: null,
    passwordUpdateStatus: 'idle',
    passwordUpdateError: null,
  },
  reducers: {
    logout: (state) => {
      state.doctorData = null;
      state.appointments = [];
      state.status = 'idle';
      state.error = null;
      state.otpStatus = 'idle';
      state.otpError = null;
      state.resendOtpStatus = 'idle';
      state.resendOtpError = null;
      state.otpSent = false;
      localStorage.removeItem('doctorData');
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling sending OTP during doctor registration
      .addCase(sendOtp.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.otpSent = false;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
        state.otpSent = true;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Ensure this captures the error object
        state.otpSent = false;
      })
    
      // Handling resending OTP
      .addCase(resendOtp.pending, (state) => {
        state.resendOtpStatus = 'loading';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.resendOtpStatus = 'succeeded';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.resendOtpStatus = 'failed';
        state.resendOtpError = action.payload; // action.payload is in the standardized format
      })
      // Handling OTP verification
      .addCase(verifyOtp.pending, (state) => {
        state.otpStatus = 'loading';
        state.otpError = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.otpStatus = 'succeeded';
        state.doctorData = action.payload;
        state.error = null;
        state.otpError = null;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpStatus = 'failed';
        state.otpError = action.payload; // action.payload is in the format { errors: [...] }
      })
      // Handling doctor login
      .addCase(loginDoctor.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginDoctor.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.doctorData = action.payload;
        state.error = null;
      })
      .addCase(loginDoctor.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handling fetching doctor appointments
      .addCase(fetchDoctorAppointmentsByDate.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchDoctorAppointmentsByDate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.appointments = action.payload;
      })
      .addCase(fetchDoctorAppointmentsByDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(requestPasswordReset.pending, (state) => {
        state.passwordResetStatus = 'loading';
        state.passwordResetError = null;
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.passwordResetStatus = 'succeeded';
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.passwordResetStatus = 'failed';
        state.passwordResetError = action.payload;
      })
  
      // Handling OTP verification
      .addCase(verifyResetOtp.pending, (state) => {
        state.otpVerificationStatus = 'loading';
        state.otpVerificationError = null;
      })
      .addCase(verifyResetOtp.fulfilled, (state, action) => {
        state.otpVerificationStatus = 'succeeded';
      })
      .addCase(verifyResetOtp.rejected, (state, action) => {
        state.otpVerificationStatus = 'failed';
        state.otpVerificationError = action.payload;
      })
  
      // Handling password update
      .addCase(resetPassword.pending, (state) => {
        state.passwordUpdateStatus = 'loading';
        state.passwordUpdateError = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.passwordUpdateStatus = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.passwordUpdateStatus = 'failed';
        state.passwordUpdateError = action.payload;
      });
  },
});

export const { logout } = doctorSlice.actions;
export default doctorSlice.reducer;
