// ChatApp.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ChatList from '../Components/ChatList';
import ChatWindow from '../Components/ChatWindow';

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChatListContainer = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isMobileView }) => (isMobileView ? 'block' : 'none')};
  }
`;

const ChatApp = () => {
  const [selectedUser, setSelectedUser] = useState(() => {
    const storedUser = localStorage.getItem('selectedUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [isMobileView, setIsMobileView] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (!currentUser) {
      console.log('User not logged in.');
    } else {
      console.log('currentUser:', currentUser);
    }

    // Function to check if the device is mobile
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    // Initial check
    checkMobile();

    // Add event listener
    window.addEventListener('resize', checkMobile);

    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, [currentUser]);

  const generateRoomName = (user1Id, user2Id) => {
    return [user1Id, user2Id].sort().join('_');
  };

  if (!currentUser) {
    return <div>Please log in to view your chats.</div>;
  }

  const currentUserId = currentUser.patient?.user?.id || currentUser.id;
  console.log(currentUserId, currentUser);

  // Handler to navigate back to ChatList on mobile
  const handleBack = () => {
    setSelectedUser(null);
    localStorage.removeItem('selectedUser'); // Remove from localStorage
  };

  // Determine if we're in mobile view and which component to show
  const showChatList = isMobileView ? !selectedUser : true;
  const showChatWindow = isMobileView ? selectedUser : true;

  // Handler to set selectedUser and persist it
  const handleSetSelectedUser = (user) => {
    setSelectedUser(user);
    if (user) {
      localStorage.setItem('selectedUser', JSON.stringify(user));
    } else {
      localStorage.removeItem('selectedUser');
    }
  };

  return (
    <ChatAppContainer>
      <ChatListContainer isMobileView={showChatList}>
        <ChatList
          setSelectedUser={(user) => {
            handleSetSelectedUser(user);
          }}
        />
      </ChatListContainer>

      <ChatWindowContainer isMobileView={showChatWindow}>
        {isMobileView && selectedUser && (
          <ChatWindow
            selectedUser={selectedUser}
            roomName={generateRoomName(selectedUser.user.id, currentUserId)}
            currentUser={currentUser}
            onBack={handleBack} // Pass the back handler
          />
        )}
        {!isMobileView && selectedUser && (
          <ChatWindow
            selectedUser={selectedUser}
            roomName={generateRoomName(selectedUser.user.id, currentUserId)}
            currentUser={currentUser}
          />
        )}
        {!selectedUser && !isMobileView && (
          <div style={{ padding: '20px' }}>Select a chat to start messaging.</div>
        )}
      </ChatWindowContainer>
    </ChatAppContainer>
  );
};

export default ChatApp;
