import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import '../CSS/CompassionateCare.css';

const CompassionateCare = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    // Array of reviews with different colors for each reviewer
    const reviews = [
        {
            name: 'Navita Tomar',
            designation: 'Doctor, Co-founder',
            review: 'Our mission is to ensure that every patient receives the best medical care possible. We provide personalized, compassionate care and expert medical opinions that prioritize your well-being.',
            rating: '★★★★★',
            color: '#ff6b6b' // Example color for Navita Tomar
        },
        {
            name: 'Virendra Singh',
            designation: 'Patient',
            review: 'The medical advice I received here changed my life. The professionalism and compassion shown by the doctors made the entire process smooth and reassuring.',
            rating: '★★★★★',
            color: '#4CAF50' // Example color for Virendra Singh
        },
        {
            name: 'Mudit Arya',
            designation: 'Patient',
            review: 'The medical opinion I received helped me make an informed decision about my health. The entire process was simple, reliable, and quick.',
            rating: '★★★★★',
            color: '#ac054e' // Example color for Mudit Arya
        }
    ];

    // Auto-slider functionality
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000); // Change review every 5 seconds

        return () => clearInterval(interval);
    }, [reviews.length]);

    // Function to extract first letter of name
    const getInitial = (name) => name.charAt(0).toUpperCase();

    return (
        <div className="compassionate-care">
            <div className="banner">
                <div className="overlay">
                    <div className="play-button-1">
                        <i className="fas fa-play"></i>
                    </div>
                    <h1>We Care With Compassion</h1>
                    <p>
                        Access ethical, cost-effective medical opinions and medical tourism services with top doctors worldwide. We offer trusted medical advice and seamless travel arrangements for high-quality care, ensuring your health and well-being are prioritized every step of the way.
                    </p>
                </div>
            </div>
            <div className="testimonial">
                <div className="testimonial-content">
                    <h2>What They Say?</h2>
                    <h3>Love To Serve You, With Compassionate Care</h3>
                    <p>
                        Our top priority is your well-being. We offer personalized, high-quality medical services, expert second opinions, and access to world-class healthcare, ensuring the best outcomes at every step.
                    </p><Link to="/bookappointment">
                        <button className="contact-button">Book Now</button>
                    </Link>
                </div>
                <div className="testimonial-review">
                    <div className="rating">{reviews[currentReviewIndex].rating}</div>
                    <div className="review">
                        <p>{reviews[currentReviewIndex].review}</p>
                    </div>
                    <div className='reviewer-section'>
                        <div className="reviewer">
                            <div
                                className="reviewer-initial"
                                style={{ backgroundColor: reviews[currentReviewIndex].color }}
                            >
                                {getInitial(reviews[currentReviewIndex].name)}
                            </div>

                            <div className="reviewer-info">
                                <strong>{reviews[currentReviewIndex].name}</strong>
                                <span>{reviews[currentReviewIndex].designation}</span>
                            </div>


                        </div>
                        <div className='double-quote-icon'>
                            <img
                                
                                src="static/icons/quote.webp"
                                alt='double-quote'
                                
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompassionateCare;
