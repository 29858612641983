// PatientProfile.js
import React, { useState, useEffect, useRef } from 'react';
import { FaHeart, FaHeartbeat, FaEllipsisV, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import '../CSS/PatientProfile.css'; // Retaining existing CSS

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

// Styled-components for the dropdown menu
const DropdownMenu = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeIn 0.2s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const MenuItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const StartChatButton = styled.button`
  margin: 20px;
  padding: 10px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #27ae60;
  }
`;

const PatientProfile = ({ selectedUser, onStartChat }) => {
  const name = selectedUser?.name || 'Unknown';
  console.log(selectedUser);
  const { gender, dob, height, weight, bloodPressure, pulse, problem, medicalHistory, labResults } = selectedUser || {};

  // State for managing the visibility of the menu
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Toggle the menu visibility
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Handler functions for menu actions
  const handleViewDetails = () => {
    // Implement view details functionality
    alert('View Details clicked');
    setMenuOpen(false);
  };

  const handleEditProfile = () => {
    // Implement edit profile functionality
    alert('Edit Profile clicked');
    setMenuOpen(false);
  };

  const handleDeleteProfile = () => {
    // Implement delete profile functionality
    alert('Delete Profile clicked');
    setMenuOpen(false);
  };

  return (
    <div className="patient-profile-container">
      <div className="profile-header">
        <div className="profile-initial">
          {name.charAt(0)}
        </div>
        <div className="profile-info">
          <h2>{name}</h2>
          <p>{gender}</p>
        </div>
        <div className="menu-container" ref={menuRef}>
          <FaEllipsisV className="menu-icon" onClick={toggleMenu} aria-label="Options" />
          {menuOpen && (
            <DropdownMenu>
              <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
              <MenuItem onClick={handleEditProfile}>Edit Profile</MenuItem>
              <MenuItem onClick={handleDeleteProfile}>Delete Profile</MenuItem>
            </DropdownMenu>
          )}
        </div>
      </div>

      <div className="demographics">
        <div className="demo-row">
          <div className="demo-item">
            <span className="label">DOB</span>
            <span className="value">{dob}</span>
          </div>
          <div className="demo-item">
            <span className="label">Age</span>
            <span className="value">{calculateAge(dob)}</span>
          </div>
        </div>
        <div className="demo-row">
          <div className="demo-item">
            <span className="label">Weight</span>
            <span className="value">{weight}</span>
          </div>
          <div className="demo-item">
            <span className="label">Height</span>
            <span className="value">{height}</span>
          </div>
        </div>
      </div>

      <div className="vitals">
        <div className="vital-card">
          <FaHeart className="icon" />
          <div className="vital-info">
            <h3>Blood Pressure</h3>
            <p>{bloodPressure}</p>
          </div>
        </div>
        <div className="vital-card">
          <FaHeartbeat className="icon" />
          <div className="vital-info">
            <h3>Pulse</h3>
            <p>{pulse} BPM</p>
          </div>
        </div>
      </div>

      <div className="patient-notes">
        <div className="notes-header">
          <h3>Current Problem</h3>
          <div className="notes-actions">
            <FaPlus className="action-icon" />
            <FaEllipsisV className="action-icon" />
          </div>
        </div>
        <div className="notes-content">
          <p className="notes-text">{problem}</p>
        </div>
      </div>

      <div className="patient-notes">
        <div className="notes-header">
          <h3>Medical History</h3>
          <div className="notes-actions">
            <FaPlus className="action-icon" />
            <FaEllipsisV className="action-icon" />
          </div>
        </div>
        <div className="notes-content">
          <p className="notes-text">{medicalHistory}</p>
        </div>
      </div>

      <div className="lab-results">
        <div className="lab-results-header">
          <h3>Lab Results</h3>
          <FaEllipsisV className="menu-icon" />
        </div>
        <div className="lab-results-content">
          <ul>
            {labResults && labResults.map((result, index) => (
              <li key={index}>
                {result.name} ({result.date}) - {result.fileType.toUpperCase()}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Start Chat Button */}
      {!onStartChat && (
        <StartChatButton onClick={onStartChat}>Start Chat</StartChatButton>
      )}
    </div>
  );
};

// Styled-component for the Start Chat button


export default PatientProfile;
