import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk for booking an appointment
export const bookAppointment = createAsyncThunk(
  'appointment/book',
  async (appointmentData, { getState, rejectWithValue }) => { // Get getState from the second argument
    try {
      let userData = getState().user.userData;
      // If userData is not in state, try to get it from localStorage
      if (!userData) {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          userData = JSON.parse(storedUserData);
        }
      }

      if (!userData || !userData.access_token) {
        return rejectWithValue('User is not authenticated');
      }

      const response = await fetch(`/api/appointments/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.access_token}`, // Add token if needed
        },
        body: JSON.stringify(appointmentData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for verifying the payment after successful transaction
export const verifyPayment = createAsyncThunk(
  'appointment/verifyPayment',
  async (paymentData, { getState, rejectWithValue }) => { // Get getState from the second argument
    let userData = getState().user.userData;
    // If userData is not in state, try to get it from localStorage
    if (!userData) {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        userData = JSON.parse(storedUserData);
      }
    }

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await fetch(`/api/verify_payment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':  `Bearer ${userData.access_token}`,
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Redux slice
const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    appointments: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Booking an appointment
    builder
      .addCase(bookAppointment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bookAppointment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.appointments.push(action.payload);
      })
      .addCase(bookAppointment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

    // Verifying the payment
    builder
      .addCase(verifyPayment.pending, (state) => {
        state.status = 'verifying';
      })
      .addCase(verifyPayment.fulfilled, (state, action) => {
        state.status = 'payment_verified';
        const updatedAppointment = state.appointments.find(
          appointment => appointment.id === action.payload.appointment_id
        );
        if (updatedAppointment) {
          updatedAppointment.status = 'Confirmed';
        }
      })
      .addCase(verifyPayment.rejected, (state, action) => {
        state.status = 'verification_failed';
        state.error = action.payload;
      });
  },
});

export default appointmentSlice.reducer;
