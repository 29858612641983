import React from 'react'
import About from '../Components/About'
import AboutUs from '../Components/AboutUs'
import Bannersmall from '../Components/Bannersmall'
function AboutUS () {
  const imageUrl = '/static/banner/aboutus.webp';
    const altText = 'Your Banner Description';
  return (
    <div>
      <Bannersmall imageUrl={imageUrl} altText={altText} />
         <About />
    </div>
  )
}

export default AboutUS
