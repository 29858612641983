import React, { useState, useEffect, useRef } from 'react';

const OtpModal = ({
  isOpen,
  onClose,
  otp,
  setOtp,
  onSubmit,
  onResend,
  error,
  loading,
  resendOtpStatus,
  resendOtpError,
}) => {
  const [timer, setTimer] = useState(60);
  const inputRefs = useRef([]);

  useEffect(() => {
    let interval = null;
    if (isOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOpen, timer]);

  useEffect(() => {
    console.log('OTP Error:', error);
    console.log('Resend OTP Error:', resendOtpError);
  }, [error, resendOtpError]);

  useEffect(() => {
    if (isOpen) {
      setTimer(60);
      // Optionally, focus on the first input when modal opens
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = otp.split('');
    newOtp[index] = value;
    setOtp(newOtp.join(''));

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleResend = () => {
    onResend();
    setTimer(60);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index]) {
        const newOtp = otp.split('');
        newOtp[index] = '';
        setOtp(newOtp.join(''));
      } else if (index > 0) {
        inputRefs.current[index - 1].focus();
        const newOtp = otp.split('');
        newOtp[index - 1] = '';
        setOtp(newOtp.join(''));
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Helper function to safely render error messages
  const renderErrorMessage = (error) => {
    if (!error) return '';
    if (typeof error === 'string') return error;

    if (Array.isArray(error.errors)) {
      return error.errors.map((err, index) => <div key={index}>{err.message}</div>);
    }

    if (error.message) {
      return error.message;
    }

    return 'An unknown error occurred.';
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button onClick={onClose} style={styles.closeButton}>
          ×
        </button>
        <div style={styles.iconContainer}>
          <img src="/static/icons/email.webp" alt="send otp" className="send-otp" />
        </div>
        <p style={styles.text}>
          We have sent a verification code to your registered Email. Please enter the verification code below.
        </p>
        <div style={styles.otpContainer}>
          {[...Array(6)].map((_, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              style={styles.otpInput}
              value={otp[index] || ''}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
        {error && <p style={styles.errorMessage}>{renderErrorMessage(error)}</p>}
        {resendOtpError && <p style={styles.errorMessage}>{renderErrorMessage(resendOtpError)}</p>}

        {resendOtpStatus === 'succeeded' && (
          <p style={styles.successMessage}>OTP has been resent to your email.</p>
        )}
        <button onClick={onSubmit} style={styles.submitButton} disabled={loading}>
          {loading ? 'Verifying...' : <span style={styles.arrowCircle}><i className="fa-solid fa-arrow-right"></i></span>}
        </button>
        <button
          onClick={handleResend}
          style={styles.resendButton}
          disabled={timer > 0 || resendOtpStatus === 'loading'}
        >
          {resendOtpStatus === 'loading' ? 'Resending...' : timer > 0 ? `Resend Code in ${timer}s` : 'Resend Code'}
        </button>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    justifyContent: 'center',
    padding: '10px', // Added padding to prevent overflow on very small screens
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '100%', // Changed from 90% to 100% to utilize the padding
    maxWidth: '400px',
    textAlign: 'center',
    position: 'relative',
    boxSizing: 'border-box', // Ensure padding is included in width calculations
  },
  closeButton: {
    position: 'absolute',
    top: '10px', // Adjusted for better spacing
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px', // Reduced size for better appearance
    cursor: 'pointer',
  },
  iconContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '14px',
    color: '#333',
    marginBottom: '20px',
    padding: '0 10px', // Added padding for better readability on small screens
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    // Ensure the container doesn't wrap
    flexWrap: 'nowrap',
  },
  otpInput: {
    flex: '1', // Allow inputs to grow equally
    maxWidth: '50px', // Set a maximum width
    width: '100%', // Make inputs responsive
    height: '40px',
    fontSize: '24px',
    textAlign: 'center',
    border: 'none',
    borderBottom: '2px solid #ccc',
    margin: '0 5px',
    outline: 'none',
    boxSizing: 'border-box',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
    marginBottom: '10px',
    padding: '0 10px', // Added padding for better readability
  },
  successMessage: {
    color: 'green',
    fontSize: '14px',
    marginBottom: '10px',
    padding: '0 10px', // Added padding for better readability
  },
  submitButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '35px',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65px',
    height: '65px',
    borderRadius: '50%',
    border: '2px solid #840442',
    color: '#840442',
  },
  resendButton: {
    backgroundColor: 'transparent',
    color: '#840442',
    border: 'none',
    padding: '10px 20px',
    fontSize: '14px',
    cursor: 'pointer',
    width: '100%',
  },
};

export default OtpModal;
