import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginDoctor, requestPasswordReset, verifyResetOtp, resetPassword } from '../slices/doctorSlice';
import { Link, useNavigate } from 'react-router-dom';
import TestimonialSection from '../Components/TestimonialSection';
import '../CSS/DoctorRegistrationForm.css';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [email, setEmail] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); // 1: Login, 2: Enter Email, 3: Enter OTP, 4: Enter New Password

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loading,
    error,
    doctorData,
    passwordResetStatus,
    passwordResetError,
    otpVerificationStatus,
    otpVerificationError,
    passwordUpdateStatus,
    passwordUpdateError,
  } = useSelector((state) => state.doctor);

  // Redirect to dashboard if logged in
  useEffect(() => {
    if (doctorData && doctorData.access_token) {
      navigate('/doctors/doctordashboard');
    }
  }, [doctorData, navigate]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (step === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else if (step === 2) {
      setEmail(value);
    } else if (step === 3) {
      setResetToken(value);
    } else if (step === 4) {
      setNewPassword(value);
    }
  };

  // Handle form submissions
  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      dispatch(loginDoctor(formData));
    } else if (step === 2) {
      dispatch(requestPasswordReset(email));
    } else if (step === 3) {
      dispatch(verifyResetOtp({ email, reset_token: resetToken }));
    } else if (step === 4) {
      dispatch(resetPassword({ email, reset_token: resetToken, new_password: newPassword }));
    }
  };

  // Move to the next step based on the action results
  useEffect(() => {
    if (passwordResetStatus === 'succeeded' && step === 2) {
      setStep(3);
    }
  }, [passwordResetStatus, step]);

  useEffect(() => {
    if (otpVerificationStatus === 'succeeded' && step === 3) {
      setStep(4);
    }
  }, [otpVerificationStatus, step]);

  useEffect(() => {
    if (passwordUpdateStatus === 'succeeded' && step === 4) {
      // Password reset successful, reset to login step
      setStep(1);
      setFormData({ username: '', password: '' });
      setEmail('');
      setResetToken('');
      setNewPassword('');
    }
  }, [passwordUpdateStatus]);

  // Render error messages
// Render error messages
const renderErrorMessages = (errorResponse) => {
  if (!errorResponse) return null;

  const errors = errorResponse.errors;
  if (!errors) return null;

  // Combine all error messages into one string
  const errorMessages = errors
    .map((error) => getFriendlyErrorMessage(error.code))
    .join(' '); // You can change the separator if needed

  return (
    <p className="error-message">
      {errorMessages}
    </p>
  );
};


  const getFriendlyErrorMessage = (errorCode) => {
    const errorMessages = {
      INVALID_CREDENTIALS: 'Invalid username or password.',
      USER_NOT_FOUND: 'No account found with this email.',
      NETWORK_ERROR: 'Network error occurred. Please check your connection.',
      OTP_EXPIRED: 'The OTP has expired. Please request a new one.',
      INVALID_OTP: 'The OTP you entered is incorrect.',
      RESET_CODE_NOT_FOUND: 'No reset code found for this email.',
      PASSWORD_RESET_FAILED: 'Failed to reset your password. Try again.',
      EMAIL_REQUIRED: 'Please enter your email address.',
      // Default message
      default: 'Something went wrong. Please try again later.',
    };

    return errorMessages[errorCode] || errorMessages.default;
  };

  return (
    <div className="login-container">
      <form className="form-section" onSubmit={handleSubmit}>
        <div className="logo-placeholder">
          <div className="logo-box">
            <img
              src="/static/icons/logo.webp"
              alt="Medbridge-access-logo"
              className="Medbridge-access-logo"
            />
            <div className="logo">
              <h1>
                <span className="title-we">Med</span>
                <div className="title-2">
                  <span>Bridge</span> <span>Access</span>
                </div>
              </h1>
            </div>
          </div>
        </div>
       <div className='form-input-section'>
        {step === 1 && (
          <>
            <h3>Account Access</h3>
            <p className="form-subheading">Welcome back! Please login to your account.</p>
            {error && <p className="error-class">{renderErrorMessages(error)}</p>}
            
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={handleChange}
              className="form-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              className="form-input"
              required
            />
            <a
              href="#"
              className="forgot-password-doctor"
              onClick={(e) => {
                e.preventDefault();
                setStep(2);
              }}
            >
              Forgot Password?
            </a>
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? 'Logging In...' : 'Log In'}
            </button>
            <p className="form-end">
              Don't have an account? <Link to="/doctors/registration">Sign Up</Link>
            </p>
          </>
        )}

        {step === 2 && (
          <>
            <h3>Reset Password</h3>
            <p className="form-subheading">Enter your registered email to receive a reset code.</p>
            {passwordResetError && renderErrorMessages(passwordResetError)}
            <input
              type="email"
              name="email"
              placeholder="Registered Email"
              onChange={handleChange}
              className="form-input forgot-mail-field "
              required
            />
            <button
              type="submit"
              disabled={passwordResetStatus === 'loading'}
              className="submit-button"
            >
              {passwordResetStatus === 'loading' ? 'Sending Code...' : 'Send Reset Code'}
            </button>
            <button
              type="button"
              className="back-button"
              onClick={() => setStep(1)}
            >
              Back to Login
            </button>
          </>
        )}

        {step === 3 && (
          <>
            <h3>Verify Reset Code</h3>
            <p className="form-subheading">Enter the OTP sent to your email.</p>
            {otpVerificationError && renderErrorMessages(otpVerificationError)}
            <input
              type="text"
              name="resetToken"
              placeholder="Reset Code"
              onChange={handleChange}
              className="form-input forgot-mail-field"
              required
            />
            <button
              type="submit"
              disabled={otpVerificationStatus === 'loading'}
              className="submit-button"
            >
              {otpVerificationStatus === 'loading' ? 'Verifying...' : 'Verify Code'}
            </button>
            <button
              type="button"
              className="back-button"
              onClick={() => setStep(2)}
            >
              Back
            </button>
          </>
        )}

        {step === 4 && (
          <>
            <h3>Set New Password</h3>
            <p className="form-subheading">Enter your new password.</p>
            {passwordUpdateError && renderErrorMessages(passwordUpdateError)}
            <input
              type="password"
              name="newPassword"
              placeholder="New Password"
              onChange={handleChange}
              className="form-input"
              required
            />
            <button
              type="submit"
              disabled={passwordUpdateStatus === 'loading'}
              className="submit-button"
            >
              {passwordUpdateStatus === 'loading' ? 'Updating...' : 'Reset Password'}
            </button>
          </>
        )}
        </div>
      </form>

      <TestimonialSection />
    </div>
  );
};

export default LoginPage;
