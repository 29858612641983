// serviceSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const addService = createAsyncThunk(
  'service/addService',
  async (serviceData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/services/', serviceData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchServices = createAsyncThunk(
  'service/fetchServices',
  async ({ page = 1, pageSize = 10 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/services/', {
        params: { page, page_size: pageSize },
      });
      console.log(response.data); // Should log an array of services
      const services = response.data; // response.data is an array
      const hasMore = services.length === pageSize; // Determine if more data is available
      return { services, hasMore };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchServiceDetails = createAsyncThunk(
  'service/fetchServiceDetails',
  async (serviceId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/services/${serviceId}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    services: [],
    currentService: null,
    currentPage: 1,
    hasMore: true,
    servicesStatus: 'idle',
    serviceDetailsStatus: 'idle',
    error: null,
  },
  reducers: {
    resetCurrentService: (state) => {
      state.currentService = null;
      state.serviceDetailsStatus = 'idle';
    },
    resetServices: (state) => {
      state.services = [];
      state.currentPage = 1;
      state.hasMore = true;
      state.servicesStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addService.pending, (state) => {
        state.servicesStatus = 'loading';
      })
      .addCase(addService.fulfilled, (state, action) => {
        state.servicesStatus = 'succeeded';
        state.services.push(action.payload);
      })
      .addCase(addService.rejected, (state, action) => {
        state.servicesStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchServices.pending, (state) => {
        state.servicesStatus = 'loading';
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.servicesStatus = 'succeeded';
        const { services, hasMore } = action.payload;
        state.services = [...state.services, ...services];
        state.currentPage += 1;
        state.hasMore = hasMore;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.servicesStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchServiceDetails.pending, (state) => {
        state.serviceDetailsStatus = 'loading';
      })
      .addCase(fetchServiceDetails.fulfilled, (state, action) => {
        state.serviceDetailsStatus = 'succeeded';
        state.currentService = action.payload;
      })
      .addCase(fetchServiceDetails.rejected, (state, action) => {
        state.serviceDetailsStatus = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetCurrentService, resetServices } = serviceSlice.actions;

export default serviceSlice.reducer;
