// DoctorChatApp.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PatientProfile from '../Components/PatientProfile';
import ChatWindow from '../Components/ChatWindow';
import { fetchPatientDetails, clearPatientDetails } from '../slices/patientSlice';
import { FiArrowLeft } from 'react-icons/fi'; // For mobile back button
import { FaHeart, FaHeartbeat } from 'react-icons/fa'; // For toggle buttons

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PatientProfileContainer = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const ChatWindowContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const MobileHeader = styled.div`
  display: none;
  align-items: center;
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ToggleButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ToggleButtonContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #666;
`;

const DoctorChatApp = () => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { details: patient, loading, error } = useSelector((state) => state.patient);
  const doctorData = useSelector((state) => state.doctor.doctorData);

  // State to manage view transitions
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientDetails(patientId));
    }

    return () => {
      dispatch(clearPatientDetails());
    };
  }, [dispatch, patientId]);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsChatStarted(false); // Reset chat initiation on desktop resize
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateRoomName = (user1Id, user2Id) => {
    return [user1Id, user2Id].sort().join('_');
  };

  if (loading) return <ContentPlaceholder>Loading patient details...</ContentPlaceholder>;
  if (error) return <ContentPlaceholder>Error loading patient details: {error.message}</ContentPlaceholder>;

  if (!patient || !patient.user || !doctorData || !doctorData.doctor || !doctorData.doctor.user) {
    return <ContentPlaceholder>Loading data...</ContentPlaceholder>;
  }

  // Flatten the doctor data to match the expected structure
  const currentUser = {
    ...doctorData.doctor,
    user_type: 'doctor',
    access_token: doctorData.access_token,
    refresh_token: doctorData.refresh_token,
  };

  // Ensure the patient has a user_type
  const patientWithUserType = { ...patient, user_type: 'patient' };

  // Handlers for initiating and ending chat
  const handleStartChat = () => {
    setIsChatStarted(true);
  };

  const handleEndChat = () => {
    setIsChatStarted(false);
  };

  // Handlers for mobile navigation (optional based on design)
  const navigateToChat = () => {
    setIsChatStarted(true);
  };

  const navigateToProfile = () => {
    setIsChatStarted(false);
  };

  return (
    <ChatAppContainer>
      {/* Patient Profile Section */}
      {!isChatStarted && (
        <PatientProfileContainer show={!isChatStarted}>
          <PatientProfile selectedUser={patientWithUserType} onStartChat={handleStartChat} />
        </PatientProfileContainer>
      )}

      {/* Chat Window Section */}
      {isChatStarted && (
        <ChatWindowContainer show={isChatStarted}>
          {isMobile && (
            <MobileHeader>
              <ToggleButton onClick={handleEndChat} aria-label="End Chat">
                <FiArrowLeft />
              </ToggleButton>
              <h3>Chat</h3>
            </MobileHeader>
          )}
          <ChatWindow
            selectedUser={patientWithUserType}
            roomName={generateRoomName(doctorData.doctor.user.id, patient.user.id)}
            currentUser={currentUser}
            onEndChat={handleEndChat}
          />
        </ChatWindowContainer>
      )}

      {/* Start Chat Button for Desktop */}
      {!isChatStarted && (
        <StartChatButton onClick={handleStartChat}>Start Chat</StartChatButton>
      )}

      {/* Toggle Buttons for Mobile View */}
      {isMobile && !isChatStarted && (
        <ToggleButtonContainer>
          <ToggleButton onClick={navigateToChat} aria-label="Start Chat">
            <FaHeartbeat />
          </ToggleButton>
        </ToggleButtonContainer>
      )}
    </ChatAppContainer>
  );
};

// Styled-component for the Start Chat button on Desktop
const StartChatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #2980b9;
  }

  @media (max-width: 768px) {
  }
`;

export default DoctorChatApp;
