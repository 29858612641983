import React, { useState } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  padding: 5px; /* Reduced padding */
  border: 1px solid #ddd;
  border-radius: 25px;
  margin-right: 5px;
  background-color: #fff;
  word-wrap: break-word;
  overflow: hidden;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%; /* Ensure it takes up the full width of the container */
  padding: 0 10px;
`;

const Input = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  width: 100%;
  max-width: 100%;
  height: 30px; /* Set initial fixed height */
  min-height: 30px; /* Keep it consistent with the line height */
  line-height: 30px;
  resize: none;
  background-color: transparent;
  font-size: 14px;
  margin-left: 10px;
  overflow-y: hidden; /* Hide vertical scrollbar to ensure smooth resizing */
`;


const FileUpload = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  background-color: #a7185d;
  color: white;
  border-radius: 50%; /* Change button shape to a circle */
  width: 40px; /* Set width and height for the button */
  height: 40px;
  cursor: pointer;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
`;

const FileIcon = styled(Icon)`
  background-image: url('/static/icons/attach-file.webp');
`;

const SendIcon = styled(Icon)`
  background-image: url('/static/icons/send.webp');
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const FilePreviewBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const FileUploadedIcon = styled(Icon)`
  background-image: url('/static/icons/document.webp');
  width: 40px;
  height: 60px;
  background-size: cover;
  margin-bottom: 7px;
`;

const RemoveButton = styled.button`
  padding: 2px 5px;
  border: none;
  background-color: red;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -7%;
  right: -7%;
`;

const MessageInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);

  const sendMessage = () => {
    if (message || files.length > 0) {
      onSendMessage({ message, files });
      setMessage('');
      setFiles([]);
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const adjustTextareaHeight = (e) => {
    e.target.style.height = '30px'; // Set it to the minimum height first
    const newHeight = e.target.scrollHeight; // Get the scroll height
    e.target.style.height = `${newHeight}px`; // Adjust height dynamically only if needed
  };

  return (
    <InputContainer>
      <InputWrapper>
        {files.length > 0 && (
          <FilePreviewBox>
            {files.map((file, index) => (
              <FilePreview key={index}>
                <FileUploadedIcon />
                <RemoveButton onClick={() => removeFile(index)}>x</RemoveButton>
              </FilePreview>
            ))}
          </FilePreviewBox>
        )}
        <InputBox>
          <FileUpload>
            <FileIcon />
            <HiddenInput type="file" onChange={handleFileUpload} multiple />
          </FileUpload>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onInput={adjustTextareaHeight} // Dynamically adjust height as the user types
            placeholder="Type a message here..."
          />
        </InputBox>
      </InputWrapper>
      <Button onClick={sendMessage}>
        <SendIcon />
      </Button>
    </InputContainer>
  );
};

export default MessageInput;
