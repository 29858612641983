import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../CSS/QuoteModal.css'; // Updated CSS file for QuoteModal

const QuoteModal = ({ isOpen, onClose }) => {
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Get user information from the Redux store
  const userState = useSelector((state) => state.user.userData);
  const {
    patient: {
      user: { username = '', email = '', first_name = '', last_name = '', id: userId } = {},
      phone = '',
    } = {},
  } = userState || {}; // Extract user data with default values

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!details) {
      alert('Please provide some details about the quote request.');
      return;
    }

    setLoading(true); // Start loading state

    try {
      // Prepare the quote request data with user information
      const quoteData = {
        details_description: details,
        userId, // Assuming logged-in user ID
        email,  // Contact email
        phone,  // Contact phone
      };

      // Simulate sending the quote request to backend
      // Replace with actual dispatch logic when the backend for quote is implemented
      console.log('Quote request sent:', quoteData);

      alert('Quote request submitted successfully!');

      // Reset the form and close the modal
      setDetails('');
      onClose();
    } catch (error) {
      console.error('Error submitting quote request:', error);
      alert('An error occurred while submitting your request. Please try again.');
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  if (!isOpen) return null;

  return (
    <div className="quote-modal-overlay">
      <div className="quote-modal">
        <button className="quote-close-button" onClick={onClose}>✕</button>
        <div className="quote-modal-content">
          <div className="quote-form-container">
            <h2>Get a Quote Now!</h2>
            <p>Please describe your requirements, and we will get back to you with a quote.</p>
            <form onSubmit={handleSubmit}>
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                placeholder="Describe your requirements"
                required
              />
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Request Quote'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteModal;
