import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Step 1: Send OTP during patient creation
export const sendOtp = createAsyncThunk(
  'user/sendOtp',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/create_patient/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // Assuming the response contains a message or status indicating OTP was sent
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// **New Action:** Resend OTP
export const resendOtp = createAsyncThunk(
  'user/resendOtp',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/resend_otp/', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Step 2: Verify OTP
export const verifyOtp = createAsyncThunk(
  'user/verifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/verify_otp/', { email, otp });
      // Store the complete user data
      localStorage.setItem('userData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/patient_login/', credentials);
      // Store the complete user data
      localStorage.setItem('userData', JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchAppointments = createAsyncThunk(
  'user/fetchAppointments',
  async (_, { getState, rejectWithValue }) => {
    let userData = getState().user.userData;

    // If userData is not in state, try to get it from localStorage
    if (!userData) {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        userData = JSON.parse(storedUserData);
      }
    }

    if (!userData || !userData.access_token) {
      return rejectWithValue('User is not authenticated');
    }

    try {
      const response = await axios.get('/api/get_patient_appointments/', {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'user/requestPasswordReset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_request/', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyResetOtp = createAsyncThunk(
  'user/verifyResetOtp',
  async ({ email, reset_token }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', { email, reset_token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ email, reset_token, new_password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/password_reset_confirm/', {
        email,
        reset_token,
        new_password,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }

  
);
export const updatePatient = createAsyncThunk(
  'user/updatePatient',
  async (patientData, { rejectWithValue, getState }) => {
    let userData = getState().user.userData;

    try {
      const response = await axios.put('/api/update_patient/', patientData, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      // Assuming the response contains the updated patient data
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: JSON.parse(localStorage.getItem('userData')) || null,
    status: 'idle',
    error: null,
    otpStatus: 'idle', // New state for OTP verification
    otpError: null,    // New state for OTP verification error
    resendOtpStatus: 'idle', // New state for resend OTP
    resendOtpError: null,    // New state for resend OTP error
    appointments: [],
    passwordResetStatus: 'idle',
    passwordResetError: null,
    otpVerificationStatus: 'idle',
    otpVerificationError: null,
    passwordUpdateStatus: 'idle',
    passwordUpdateError: null,
  },
  reducers: {
    logout: (state) => {
      state.userData = null;
      localStorage.removeItem('userData');
    },
  },
  extraReducers: (builder) => {
    builder
      // Handling sending OTP
      .addCase(sendOtp.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handling resending OTP
      .addCase(resendOtp.pending, (state) => {
        state.resendOtpStatus = 'loading';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.resendOtpStatus = 'succeeded';
        state.resendOtpError = null;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.resendOtpStatus = 'failed';
        state.resendOtpError = action.payload;
      })
      // Handling OTP verification
      .addCase(verifyOtp.pending, (state) => {
        state.otpStatus = 'loading';
        state.otpError = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.otpStatus = 'succeeded';
        state.userData = action.payload;
        state.error = null;
        state.otpError = null;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpStatus = 'failed';
        state.otpError = action.payload;
      })
      // Handling user login
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userData = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handling fetching appointments
      .addCase(fetchAppointments.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.appointments = action.payload;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })  .addCase(requestPasswordReset.pending, (state) => {
        state.passwordResetStatus = 'loading';
        state.passwordResetError = null;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.passwordResetStatus = 'succeeded';
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.passwordResetStatus = 'failed';
        state.passwordResetError = action.payload;
      })

      // Handling OTP verification
      .addCase(verifyResetOtp.pending, (state) => {
        state.otpVerificationStatus = 'loading';
        state.otpVerificationError = null;
      })
      .addCase(verifyResetOtp.fulfilled, (state) => {
        state.otpVerificationStatus = 'succeeded';
      })
      .addCase(verifyResetOtp.rejected, (state, action) => {
        state.otpVerificationStatus = 'failed';
        state.otpVerificationError = action.payload;
      })

      // Handling password update
      .addCase(resetPassword.pending, (state) => {
        state.passwordUpdateStatus = 'loading';
        state.passwordUpdateError = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.passwordUpdateStatus = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.passwordUpdateStatus = 'failed';
        state.passwordUpdateError = action.payload;
      })
      .addCase(updatePatient.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update user data with the updated patient information
        state.userData.patient = action.payload.patient;
        state.error = null;
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });;
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
