import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../CSS/Footer.css'; // Assuming you have a CSS file for styling

const Footer = () => {
  const backgroundImage = '/static/banner/banner3.webp'; // Path relative to the public folder
  const location = useLocation();
  const navigate = useNavigate();

  const handleSectionClick = (path, sectionId) => (e) => {
    e.preventDefault();
    if (location.pathname === path) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(`${path}#${sectionId}`);
    }
  };

  return (
    <footer>
      <div className="footer-header"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <div className="footer-header-content">
          <h1>Global Healthcare Solutions You Can Trust</h1>
          <Link to="/bookappointment" className="contact-button">Get Started</Link>
        </div>
      </div>
      <div className="footer-box">
        <div className="footer">
          <div className="footer-content">
            <div className="footer-section about">
              <h1 className="logo-text"><span>MedBridge</span> Access</h1>
              <p>
                At MedBridge Access, we connect you with world-class healthcare providers, offering medical tourism services and expert second opinions. Your health and peace of mind are our top priorities.
              </p>
            </div>
            <div className="footer-section links">
              <h2>Quick Links</h2>
              <ul>
                <li><Link to="/aboutus">About Us</Link></li>
                <li><Link to="/services">Our Services</Link></li>
                <li>
                  <Link 
                    to="/medicaltourism/faq" 
                    onClick={handleSectionClick('/medicaltourism', 'faq')}
                  >
                    FAQs
                  </Link>
                </li>
               
              </ul>
            </div>
            <div className="footer-section links">
              <h2>Information</h2>
              <ul>
                <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                <li><Link to="/terms">Terms & Conditions</Link></li>
                <li><Link to="/refund_policy">Refund Policy</Link></li>
                <li>
                  <Link 
                    to="/contact" 
                    onClick={handleSectionClick('/', 'contact')}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-section contact">
              <h2>Contact Us</h2>
              <p>
                We're here to help you on your journey to better health.
              </p>
              <div className="contact-info">
                <p><i className="fas fa-map-marker-alt"></i> 1246 Ground Floor Sector 21 D Faridabad-121012</p>
                <p><i className="fas fa-phone"></i> +91 7428557264</p>
                <p><i className="fas fa-envelope"></i> <a href="mailto:info@medbridgeaccess.com">info@medbridgeaccess.com</a></p>
              </div>
              <div className="socials">
                <a href="#"><i className="fab fa-facebook"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            &copy; 2024 MedBridge Access. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;