import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import UserSidebar from '../Components/UserSidebar';
import MobileHeader from '../Components/MobileHeader';
import '../CSS/PatientDetails.css'; // Import CSS file for styling
import { updatePatient } from '../slices/userSlice'; // Adjust this path accordingly

const PatientDetails = () => {
  // Fetching user data from Redux state
  const userState = useSelector((state) => state.user.userData);
  const dispatch = useDispatch(); // Initialize Redux dispatch
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Safely accessing `patient` from `userState`
  const {
    user: { username = '', email = '', first_name = '' } = {},
    country = '',
    dob = '',
    gender = '',
    phone = '',
  } = userState?.patient || {};

  // State for managing form editing
  const [formData, setFormData] = useState({
    name: first_name, // Use `first_name` to store the full name
    email,
    phone,
    gender,
    dob,
    country,
  });

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    setIsEditing(false);

    // Dispatch the updated form data to the Redux action
    const updatedPatientData = {
      first_name: formData.name, // Full name stored in `first_name`
      email: formData.email,
      phone: formData.phone,
      gender: formData.gender,
      dob: formData.dob,
      country: formData.country,
    };

    // Dispatch the update action to Redux
    dispatch(updatePatient(updatedPatientData));

    console.log('Saved Data:', updatedPatientData);
  };

  // If userState is not available yet, display a loading message
  if (!userState) {
    return <div className="patient-details-loading">Loading patient details...</div>;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-overlay">
        <MobileHeader toggleSidebar={toggleSidebar} />
        <div className="dashboard-box">
          <UserSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
          <div
            className={`sidebar-overlay ${isSidebarOpen ? 'active' : ''}`}
            onClick={() => setIsSidebarOpen(false)}
          ></div>
          <div className="patient-details-container">
            <div className="patient-details-header">
              <h2>Patient Details</h2>
              <FaEdit className="patient-details-edit-icon" onClick={handleEdit} />
            </div>
            <form className="patient-details-form">
              {Object.keys(formData).map((key) => (
                <div className="patient-details-form-group" key={key}>
                  <label htmlFor={key} className="patient-details-label">
                    {key === 'name' ? 'Full Name' : key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                  <input
                    type={key === 'dob' ? 'date' : 'text'}
                    
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    disabled={!isEditing}
                    className="patient-details-input"
                  />
                </div>
              ))}
              {isEditing && (
                <button
                  type="button"
                  className="patient-details-save-button"
                  onClick={handleSave}
                >
                  Save
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
