import React from 'react';
import '../CSS/MedicalTourismBanner.css'; // You can style this component using CSS
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const MedicalTourismBanner = () => {
  const [providersRef, providersInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [patientsRef, patientsInView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="medical-tourism-banner-container">
      <div className="medical-tourism-card providers-card">
        <div className="medical-tourism-banner-image-container">
          <img
            src="/static/banner/medicaltourism1.webp"
            alt="Better Medical Tourism Association"
            className=""
          />
          <div className="medical-tourism-banner-overlay"></div>
        </div>
        <div
          className={`medical-tourism-banner-content ${
            providersInView ? 'animate' : ''
          }`}
          ref={providersRef}
        >
          <h2 className="medical-tourism-banner-title">
            Global Healthcare Access Made Simple & Affordable
          </h2>
          <p className="medical-tourism-banner-description">
            MedBridge Access offers a comprehensive platform for medical
            tourists, providing tailored travel packages, easy payment
            solutions, and a network of trusted healthcare professionals.
            Enjoy peace of mind on your healthcare journey.
          </p>
          <Link to="/aboutus">
            <button className="medical-tourism-banner-learn-more-btn">
              Learn More →
            </button>
          </Link>
        </div>
      </div>

      <div className="medical-tourism-card patients-card">
        <div className="medical-tourism-banner-image-container">
          <img
            src="/static/banner/medicaltourism.webp"
            alt="Better Medical Tourism Association"
            className=""
          />
          <div className="medical-tourism-banner-overlay"></div>
        </div>
        <div
          className={`medical-tourism-banner-content ${
            patientsInView ? 'animate' : ''
          }`}
          ref={patientsRef}
        >
          <h2 className="medical-tourism-banner-title">
            Healthcare for Everyone, Everywhere
          </h2>
          <ul className="medical-tourism-banner-features-list">
            <li>Healthcare for every budget</li>
            <li>Completely free consultations</li>
            <li>No commitment required</li>
          </ul>
          <Link to="/bookappointment">
            <button className="medical-tourism-banner-get-quote-btn">
              Get a Free Quote →
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MedicalTourismBanner;
