import React, { useState } from 'react';
import '../CSS/FAQ.css'; // Include your CSS file for styling
import { InView } from 'react-intersection-observer';

const FAQ = () => {
  // Define the new set of questions and answers
  const faqs = [
    {
      question: "What is medical tourism?",
      answer: "Medical tourism refers to traveling to another country to receive medical care. This can include various treatments, surgeries, or wellness procedures that may be unavailable or more expensive in one’s home country."
    },
    {
      question: "Why do people travel for medical care?",
      answer: "Common reasons include lower costs, higher quality of care, access to specialized treatments, shorter waiting times, and combining medical care with a vacation."
    },
    {
      question: "What types of medical procedures are common in medical tourism?",
      answer: "Popular procedures include cosmetic surgery, dental treatments, fertility treatments, cardiac surgeries, orthopedic surgeries, and cancer treatments."
    },
    {
      question: "How do I choose the right medical facility abroad?",
      answer: "It is important to research the accreditation of the hospital or clinic, the qualifications of the healthcare providers, reviews from other patients, and the overall quality of care."
    },
    {
      question: "Is medical care safe in other countries?",
      answer: "Many international hospitals are accredited by organizations such as Joint Commission International (JCI) or ISO, which ensure they meet global safety and quality standards. It’s essential to do thorough research before selecting a facility."
    },
    {
      question: "How do I know if I am a good candidate for medical tourism?",
      answer: "Your eligibility for medical tourism depends on your health, the nature of the treatment, and the destination country. It’s advisable to consult with your healthcare provider before planning any medical travel."
    },
    {
      question: "What are the risks associated with medical tourism?",
      answer: "Risks can include complications from surgery, infections, communication barriers, follow-up care issues, and legal recourse difficulties in case of malpractice."
    },
    {
      question: "How much money can I save by traveling for medical treatment?",
      answer: "Costs vary, but patients can save anywhere from 30% to 80% on medical procedures abroad compared to their home country."
    },
    {
      question: "Will my health insurance cover treatment abroad?",
      answer: "Some insurance providers offer coverage for international medical treatments, but it’s important to check with your insurer before making any arrangements."
    },
    {
      question: "Can I combine medical treatment with a vacation?",
      answer: "Yes, many people choose destinations where they can recover in a relaxing environment, but it is crucial to consult your doctor about recovery guidelines before making any travel plans."
    },
    // Add more FAQs as needed
  ];

  // State to manage which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle the visibility of the answer
  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container" id="faq">
      <h2>Frequently Asked Questions</h2>
      <ul className="faq-list">
        {faqs.map((faq, index) => (
          <InView key={index} threshold={0.1} triggerOnce>
            {({ inView, ref }) => (
              <li
                ref={ref}
                className={`faq-item ${inView ? 'animate' : ''}`}
                style={{ transitionDelay: `${index * 100}ms` }}
              >
                <div className="faq-question" onClick={() => toggleAnswer(index)}>
                  {faq.question}
                  <span className="faq-arrow">{openIndex === index ? '▼' : '►'}</span>
                </div>
                {openIndex === index && <div className="faq-answer">{faq.answer}</div>}
                <hr className="faq-divider" />
              </li>
            )}
          </InView>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
