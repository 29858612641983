// MedicalTourismOptions.jsx
import React from 'react';
import '../CSS/MedicalTourismOptions.css';
import { InView } from 'react-intersection-observer';

const options = [
  {
    text: 'Need a second opinion? MedBridge Access connects you with leading medical experts worldwide, empowering you to make confident and informed healthcare decisions.',
    buttonText: 'Get a Second Opinion',
    buttonLink: '/bookappointment',
  },
  {
    text: 'Dreaming of medical tourism? Explore our in-depth destination guides that blend top-tier healthcare with unforgettable travel experiences. Find the best hospitals, renowned specialists, and comfortable accommodations tailored to your needs.',
    buttonText: 'Explore Destinations',
    buttonLink: '/destination-guides',
  },
  {
    text: 'Searching for specific treatments? MedBridge Access offers a comprehensive selection of over 1,000 treatments worldwide, ensuring you find the perfect procedure that suits your health requirements.',
    buttonText: 'Discover Treatments',
    buttonLink: '/services',
  },
];

const MedicalTourismOptions = () => {
  return (
    <div className="medical-tourism-container">
      <div className="medical-tourism-header">
        <h2>Discover Your Global Healthcare Options</h2>
      </div>
      <div className="medical-tourism-content">
        <div className="medical-tourism-image-section">
          <img src="/static/banner/banner4.webp" alt="Person with arms raised" />
        </div>
        <div className="medical-tourism-info-section">
          {options.map((option, index) => (
            <InView key={index} threshold={0.1} triggerOnce>
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={`medical-tourism-info-box ${inView ? 'animate' : ''}`}
                  style={{ transitionDelay: `${index * 200}ms` }}
                >
                  <p>{option.text}</p>
                  <button onClick={() => window.location.href = option.buttonLink}>
                    {option.buttonText}
                  </button>
                </div>
              )}
            </InView>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MedicalTourismOptions;
