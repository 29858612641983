import React from 'react';
import '../CSS/Terms.css'
import Bannersmall from '../Components/Bannersmall';
const PrivacyPolicy = () => {
    const imageUrl = '/static/banner/privacy.webp';
    const altText = 'Your Banner Description';
    return (
        <div>
            <Bannersmall imageUrl={imageUrl} altText={altText} />
            <div className='terms-page'>
                <div className="terms-content privacy-policy">
                <p className="c6"><span className="c3">Privacy Policy</span></p>
    <p className="c1"><span className="c2">This policy covers how MedBridge Access LLP uses your personal information. We take
            your privacy seriously and will take all measures to protect the personal information provided by you. Any
            personal information received will only be used to complete your order and Leveraged for the creation of a
            secured Health Record for you and your doctor&rsquo;s use during the consultation. The information is kept
            confidential we will not sell or redistribute your information to anyone. All the information provided on
            this website by a user/patient, including sensitive personal information, is voluntary. The user/patient has
            the right to withdraw his/her/its consent at any point in time, in accordance with the terms of this privacy
            policy, but please note that the withdrawal of consent will not be retroactive. But be aware when giving out
            any personally identifiable information about yourself or your family members in use of any Services. The
            company recognizes the importance of protecting your personal and financial information when you visit our
            website located at www.medbridgeaccess.com (&ldquo;Website&rdquo;) and interact with the offerings on the
            Website (the &ldquo;Services&rdquo;). The given information is designed to help you understand the
            information collection practices at this Website.</span></p>
    <p className="c1"><span className="c2">By accessing this Website, you are accepting the practices described in this Privacy
            Policy. If you don&rsquo;t agree with the mentioned policy, please do not use the Website. Please check the
            Terms and Conditions to learn of other terms and conditions applicable to your use of this Website.</span>
    </p>
  
    <p className="c1"><span className="c3">CHANGES TO THIS&nbsp;PRIVACY POLICY:</span></p>
    <p className="c1"><span className="c2">We suggest that you check this policy frequently for any changes. We reserve the
            right to modify, change, add, or remove any portion of this Privacy Policy at any point in time for any
            reason without any prior notification. The changes made shall be effective immediately upon posting. The
            current version of this Privacy Policy can be accessed from the link on our homepage, or at the bottom of
            our other Website pages. You acknowledge that by accessing our Website after we have posted changes to this
            Privacy Policy, you are agreeing to the terms of the Privacy Policy as modified.</span></p>
    <p className="c1"><span className="c3">INFORMATION COLLECTION&nbsp;AND USE</span></p>

    <p className="c1"><span className="c3">Personally Identifiable Information:</span></p>
    <p className="c1"><span className="c2">The personally identifiable information you submit to our Website is used only to
            provide you with information about MedBridge Access LLP products and services. The use of the Website
            entails collection of personal information such as, but not limited to, name, address, e-mail address,
            telephone number, medical background, details of any medical tests and records, as well as other information
            needed solely for the purpose of providing the Services as mentioned in this Privacy Policy as well as the
            Terms and Conditions. We will not share, sell or rent any of your personally identifiable information to
            others in contravention of this Privacy Policy. You can contact us by email at support@medbridgeaccess.com
            to update your account information whenever such information ceases to be complete or accurate. furthermore,
            if you are accessing any part of this Website that is password-protected, then (a) once you submit your
            password and enter, this Website will recognize who you are and will automatically collect all information
            that you submit, including all electronic information (including all transaction information), and (b) any
            information collected about you from this Website may be connected with other identifying information that
            we have about you.</span></p>
    <p className="c1"><span className="c3">Cookies:</span></p>
    <p className="c1"><span className="c2">We may collect certain aggregate and non-personal information when you visit the
            Website and collect the information through &ldquo;cookie&rdquo; technology. These are bits of text that can
            be placed on your computer&rsquo;s hard drive when you visit certain web sites to enhance your online
            experience by saving your preferences while you are visiting a particular site. The &ldquo;help&rdquo;
            portion in the toolbar on most browsers will tell you how to stop accepting new cookies, how to be notified
            when you receive a new cookie, and how to disable existing cookies. Without the cookies, you may not be able
            to take full benefit of all of the Site features.</span></p>
    <p className="c0"><span className="c5"></span></p>
                </div>
                </div>
            </div>
        
    );
};

export default PrivacyPolicy;
