import React, { useEffect, useRef, useState } from 'react';
import '../CSS/Form.css';

function Form() {
  const formRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the form is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing after it's visible
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  return (
    <div className='form-container' id="contact">
      <div className="steps">
    <h2>How It Work ?</h2>
    <h3>The Amazing Steps Of Our Treatment</h3>
    <div className="step">
      <div className="step-number">1</div>
      <div className="step-description">
        <h4>Primary Medical Opinion</h4>
        <p> Get expert evaluations and accurate diagnoses from top healthcare professionals to guide your treatment decisions.</p>
      </div>
      </div>
    <div className="step">
      <div className="step-number">2</div>
      <div className="step-description">
        <h4>Second Opinion</h4>
        <p>Receive a thorough review and confirmation of your diagnosis and treatment plan from a second expert for added assurance.</p>
      </div>
    </div>
    <div className="step">
      <div className="step-number">3</div>
      <div className="step-description">
        <h4>Medical Tourism</h4>
        <p>Access world-class medical care abroad, tailored to your needs and budget, with full support and coordination services.</p>
      </div>
    </div>
  </div>
      <div
        ref={formRef}
        className={`form ${isVisible ? 'visible' : ''}`}
      >
        <h3>Fill The Form</h3>
        <h1>Get In Touch</h1>
        <form>
          <input type="text" placeholder="Your Name" />
          <input type="email" placeholder="Your Email" />
          <input type="text" placeholder="Your Number" />
          <textarea placeholder="Your Message"></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Form;
