import React from "react";
import Slider from "react-slick";

const testimonials = [
  {
    quote:
      "Doctors are not just healers of the body but also of the mind and soul. Their responsibility is beyond medicine; it is to provide hope.",
    author: "Dr. Sarah Miles",
    profession: "Cardiologist",
  },
  {
    quote:
      "A doctor's duty is to serve humanity. They are the lifeline when people are at their most vulnerable. Every day is a mission to save lives.",
    author: "Dr. Ahmed Khan",
    profession: "Neurosurgeon",
  },
  {
    quote:
      "The motivation for a doctor comes from the smiles of the healed. The greatest reward is the life that walks out of the hospital stronger.",
    author: "Dr. Emma Roberts",
    profession: "Pediatrician",
  },
  {
    quote:
      "The responsibility of a doctor is immense. Every decision can be the difference between life and death, but this is why we chose this path—to make a difference.",
    author: "Dr. James Baker",
    profession: "Oncologist",
  },
];

const TestimonialSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="testimonial-section">
      {/* Static Background Image */}
      <div className="testimonial-image-container">
        <img
          src="/static/banner/doctor-reg.webp" 
          alt="Static Background"
          className="testimonial-static-image"
        />
      </div>

      {/* Quotes Slider */}
      <div className="testimonial-slider-container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
              <div className="testimonial-quote-box">
                <div className="testimonial-quote">"{testimonial.quote}"</div>
                <div className="testimonial-author">
                 <span className="testimonial-author-name"> {testimonial.author}</span>
                 <span className="testimonial-author-profession"> {testimonial.profession}</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
    
  );
  
};
const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <i class="fa-solid fa-arrow-right"></i>
    </div>
  );
};

// Custom previous arrow component
const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
    <i class="fa-solid fa-arrow-left"></i>
    </div>
  );}
export default TestimonialSection;
