import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/userSlice';
import '../CSS/DoctorSidebar.css';

const UserSidebar = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/bookappointment');
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-sidebar" onClick={onClose}>&times;</button>
      <div className='logo-box'>
        <img src='/static/icons/logo.webp' alt='Medbridge-access-logo' className="Medbridge-access-logo" />
        <div className="logo">
          <h1><span className="title-we">Med</span>
            <div className="title-2"><span>Bridge</span> <span>Access</span></div>
          </h1>
        </div>
      </div>

      <nav className="nav-items">
        <Link to="/userdashboard" className="nav-item" onClick={onClose}>
          <img src="/static/icons/dashboard.webp" alt="Dashboard Icon" className="nav-icon" /> Dashboard
        </Link>
        <Link to="/personal-details" className="nav-item" onClick={onClose}>
          <img src="/static/icons/user.webp" alt="User Icon" className="nav-icon" /> Personal Details
        </Link>
        <Link to="/chat" className="nav-item" onClick={onClose}>
          <img src="/static/icons/messenger.webp" alt="Messenger Icon" className="nav-icon" /> Messages
        </Link>
        <button onClick={handleLogout} className="nav-item logout-button">
          <img src="/static/icons/logout.webp" alt="Logout Icon" className="nav-icon" /> Logout
        </button>
      </nav>
    </div>
  );
};

export default UserSidebar;