import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, verifyOtp, resendOtp } from "../slices/userSlice"; // Import the actions
import { useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import OtpModal from "./OtpModal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Essential for DatePicker functionality

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const SignupForm = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [dob, setDob] = useState(null); // Changed to Date object
  const [gender, setGender] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    status,
    otpStatus,
    otpError,
    resendOtpStatus,
    resendOtpError,
    error,
    userData,
  } = useSelector((state) => state.user);

  useEffect(() => {
    // If user is already logged in, redirect to dashboard
    if (userData) {
      navigate("/userdashboard");
    }
  }, [userData, navigate]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    const userData = {
      username: email,
      first_name: name,
      last_name: "", // Adjust if needed
      email: email,
      password: password,
    };

    formData.append("user", JSON.stringify(userData));
    formData.append("phone", phoneNumber);
    formData.append("medical_history", message);
    formData.append("dob", dob ? dob.toISOString().split("T")[0] : "");
    formData.append("gender", gender ? gender.value : "");
    formData.append("country", selectedCountry ? selectedCountry.value : "");

    selectedFiles.forEach((file) => {
      formData.append("medical_reports", file);
    });

    try {
      const resultAction = await dispatch(sendOtp(formData));
      if (sendOtp.fulfilled.match(resultAction)) {
        console.log("OTP sent successfully");
        setIsOtpModalOpen(true); // Open OTP modal
      }
    } catch (err) {
      console.error("OTP sending failed:", err);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const resultAction = await dispatch(verifyOtp({ email, otp }));
      if (verifyOtp.fulfilled.match(resultAction)) {
        console.log("User verified successfully");
        setIsOtpModalOpen(false);
        navigate("/userdashboard");
      }
    } catch (err) {
      console.error("OTP verification failed:", err);
    }
  };

  const handleResendOtp = async () => {
    try {
      const resultAction = await dispatch(resendOtp(email));
      if (resendOtp.fulfilled.match(resultAction)) {
        console.log("OTP resent successfully");
      }
    } catch (err) {
      console.error("Failed to resend OTP:", err);
    }
  };

  const renderErrorMessages = (error) => {
    if (!error) return null;

    if (typeof error === "string") {
      return <p className="error-message">{getFriendlyErrorMessage(error)}</p>;
    } else if (typeof error === "object") {
      return (
        <ul className="error-message">
          {Object.entries(error).map(([key, value]) => (
            <li key={key}>
              {getFriendlyErrorMessage(key)}:{" "}
              {Array.isArray(value) ? value.join(", ") : value.toString()}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const getFriendlyErrorMessage = (errorKey) => {
    const errorMessages = {
      first_name: "Please provide your first name.",
      last_name: "Don't forget your last name!",
      email: "We need a valid email.",
      password: "A secure password is required.",
      country: "Please select your country.",
      phone: "Your phone number is required.",
      medical_history: "Please provide your medical history.",
      dob: "Date of birth is required.",
      gender: "Please select your gender.",
      otp: "Invalid OTP, please try again.",
      default: "Something went wrong, please try again later.",
    };

    return errorMessages[errorKey] || errorMessages.default;
  };

  const formRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1, // Trigger when 10% of the form is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing after it's visible
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  // Define options for country and gender selects
  const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
  }));

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  // Custom styles for React Select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#ccc",
      boxShadow: "none",
      margin: "0",
      padding: "0",
      borderRadius: "5px", // rounded corners
      "&:hover": {
        borderColor: "#888",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007BFF"
        : state.isFocused
        ? "#f0f0f0"
        : "#fff",
      color: state.isSelected ? "#fff" : "#333",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#666",
      fontSize: "0.8rem",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
    }),
    input: (provided) => ({
      ...provided,
    }),
  };

  return (
    <div className="form-container">
      <div className="steps">
        <h2>How It Works?</h2>
        <h3>The Amazing Steps Of Our Treatment</h3>
        <div className="step">
          <div className="step-number">1</div>
          <div className="step-description">
            <h4>Get Started by Signing Up</h4>
            <p>
              Fill out the quick form on the right and take the first step
              toward your journey to wellness. It’s easy and only takes a
              minute!
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <div className="step-description">
            <h4>Book Your Appointment or Request a Quote</h4>
            <p>
              Choose the perfect time for your appointment or request a
              customized quote. We’re flexible and here to fit into your
              schedule.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <div className="step-description">
            <h4>Our Executive Will Reach Out to You</h4>
            <p>
              Expect a friendly call from our executive who will confirm your
              details and set up a meeting with the doctor at a time that works
              for you.
            </p>
          </div>
        </div>
        <div className="step">
          <div className="step-number">4</div>
          <div className="step-description">
            <h4>Meet Your Doctor on Our Web Portal</h4>
            <p>
              Join your scheduled consultation with our expert doctor from the
              comfort of your home, through our secure and easy-to-use web
              portal.
            </p>
          </div>
        </div>
      </div>
      <div ref={formRef} className={`form ${isVisible ? "visible" : ""}`}>
        <h3>Sign Up</h3>
        <h1>Create Your Account</h1>
        <form onSubmit={handleSubmit}>
          {error && renderErrorMessages(error)}
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="select-wrapper">
            <Select
              id="country"
              value={selectedCountry}
              onChange={setSelectedCountry}
              options={countryOptions}
              placeholder="Choose Your Country"
              styles={customSelectStyles}
              isClearable
              className="react-select-signup"
              required
            />
          </div>
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Your Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <textarea
            placeholder="Your Medical History"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

<div className="datePickerContainer dark-theme">
      <DatePicker
        selected={dob}
        onChange={(date) => setDob(date)}
        placeholderText="Select Date of Birth"
        dateFormat="yyyy-MM-dd"
        maxDate={new Date()}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        wrapperClassName="datePicker"
        className="date-input dark-input"
        calendarClassName="dark-calendar"
        fixedHeight
        showPopperArrow={false}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div className="datepicker-header">
            <span className="current-month">
              {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
            </span>
            <div className="nav-buttons">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className="nav-button"
              >
                ←
              </button>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className="nav-button"
              >
                →
              </button>
            </div>
          </div>
        )}
      />
    </div>

          <Select
            id="gender"
            value={gender}
            onChange={setGender}
            options={genderOptions}
            placeholder="Select Gender"
            styles={customSelectStyles}
            className="react-select-signup"
            isClearable
            required
          />

          <div
            className={`upload-box ${dragging ? "dragging" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById("fileInput").click()}
          >
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
              accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
            />
            <div className="upload-content">
              <img
                src="/static/icons/upload-file.webp"
                alt="Upload Icon"
                className="upload-icon"
              />
              <p>
                Drag & Drop your prescription and report files here or click to
                upload (Optional)
              </p>
              <p className="upload-instructions">
                Supported formats: JPEG, PNG, GIF, PDF, DOC, DOCX. Max size: 15
                MB per file.
              </p>
            </div>
          </div>
          <div className="file-preview">
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-item">
                <div className="file-thumbnail">
                  <img src="/static/icons/document.png" alt="File Thumbnail" />
                </div>
                <div className="file-details">
                  <p>{file.name}</p>
                  <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                </div>
                <button
                  type="button"
                  className="remove-file"
                  onClick={() => handleRemoveFile(index)}
                >
                  ✖
                </button>
              </div>
            ))}
          </div>
          <button type="submit" disabled={status === "loading"}>
            {status === "loading" ? "Creating Account..." : "Create Account"}
          </button>
          <p className="form-end-login">
            Already have an account?{" "}
            <span
              className="login-link"
              onClick={() => setIsLoginModalOpen(true)}
            >
              Log In
            </span>
          </p>
        </form>
        {status === "failed" && error && (
          <p className="error-message">
            Error: {typeof error === "string" ? error : JSON.stringify(error)}
          </p>
        )}
      </div>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
      <OtpModal
        isOpen={isOtpModalOpen}
        onClose={() => setIsOtpModalOpen(false)}
        otp={otp}
        setOtp={setOtp}
        onSubmit={handleOtpSubmit}
        onResend={handleResendOtp} // Pass the handleResendOtp function
        error={otpError} // Pass OTP verification error
        loading={otpStatus === "loading"} // Pass loading state
        resendOtpStatus={resendOtpStatus} // Pass resend OTP status
        resendOtpError={resendOtpError} // Pass resend OTP error
      />
    </div>
  );
};

export default SignupForm;
