import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientDetails } from '../slices/patientSlice';
import { fetchDoctorAppointmentsByDate } from '../slices/doctorSlice';
import { useNavigate } from 'react-router-dom';
import { FilePenLine, Trash2, ChevronDown } from 'lucide-react';
import '../CSS/Timeline.css';

// Helper function to calculate age from DOB
const calculateAge = (dob) => {
  if (!dob) return 'N/A';
  
  try {
    const birthDate = new Date(dob);
    const today = new Date();
    
    if (isNaN(birthDate.getTime())) {
      return 'N/A';
    }
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    return age;
  } catch (error) {
    console.error('Error calculating age:', error);
    return 'N/A';
  }
};

// Helper function to format time
const formatTime = (timeString) => {
  if (!timeString) return { time: 'N/A', period: '' };
  
  try {
    const date = new Date(timeString);
    
    if (isNaN(date.getTime())) {
      return { time: 'N/A', period: '' };
    }
    
    const timeStr = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    
    const [time, period] = timeStr.split(' ');
    return { time, period };
  } catch (error) {
    console.error('Error formatting time:', error);
    return { time: 'N/A', period: '' };
  }
};

const TimelineItem = ({ appointment, onPatientClick, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const age = calculateAge(appointment.patient?.dob);
  const { time, period } = formatTime(appointment.appointment_time);
  
  const colorClasses = ['time-box-purple', 'time-box-orange', 'time-box-blue', 'time-box-green'];
  const colorClass = colorClasses[index % colorClasses.length];
  
  const truncateText = (text, maxLength) => {
    if (!text) return 'N/A';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
    <div className="timeline-item">
      {/* Desktop View */}
      <div className="timeline-item-desktop">
        <div className={`timeline-item-date ${colorClass}`}>
          <div className="timeline-time">{time}</div>
          <div className="timeline-period">{period}</div>
        </div>
        <div className="timeline-item-name">
          {appointment.patient?.user?.first_name || 'N/A'}{' '}
          {appointment.patient?.user?.last_name || ''}
        </div>
        <div className="timeline-item-age">{age}</div>
        <div className="timeline-item-medical-issue">
          {truncateText(appointment.problem_heading, 10)}
        </div>
        <div className="timeline-item-country">
          {appointment.patient?.country || 'N/A'}
        </div>
        <div className="timeline-item-gender">
          {appointment.patient?.gender || 'N/A'}
        </div>
        <div className="timeline-item-actions">
          <button 
            className="timeline-action-button timeline-edit-button"
            onClick={() => onPatientClick(appointment.patient?.id)}
            aria-label="Edit Appointment"
          >
            <FilePenLine size={20} />
          </button>
          <button 
            className="timeline-action-button timeline-delete-button" 
            aria-label="Delete Appointment"
          >
            <Trash2 size={20} />
          </button>
        </div>
      </div>

      {/* Mobile View */}
      <div className="timeline-item-mobile">
        <div className="timeline-mobile-header" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="timeline-mobile-main-info">
            <div className={`timeline-item-date ${colorClass}`}>
              <div className="timeline-time">{time}</div>
              <div className="timeline-period">{period}</div>
            </div>
            <div className="timeline-item-name">
              {appointment.patient?.user?.first_name || 'N/A'}{' '}
              {appointment.patient?.user?.last_name || ''}
            </div>
          </div>
          <ChevronDown 
            className={`timeline-mobile-chevron ${isExpanded ? 'expanded' : ''}`} 
            size={20} 
          />
        </div>

        {isExpanded && (
          <div className="timeline-mobile-details">
            <div className="timeline-mobile-row">
              <span className="timeline-mobile-label">Age:</span>
              <span className="timeline-mobile-value">{age}</span>
            </div>
            <div className="timeline-mobile-row">
              <span className="timeline-mobile-label">Medical Issue:</span>
              <span className="timeline-mobile-value">
                {appointment.problem_heading || 'N/A'}
              </span>
            </div>
            <div className="timeline-mobile-row">
              <span className="timeline-mobile-label">Country:</span>
              <span className="timeline-mobile-value">
                {appointment.patient?.country || 'N/A'}
              </span>
            </div>
            <div className="timeline-mobile-row">
              <span className="timeline-mobile-label">Gender:</span>
              <span className="timeline-mobile-value">
                {appointment.patient?.gender || 'N/A'}
              </span>
            </div>
            <div className="timeline-mobile-actions">
              <button 
                className="timeline-action-button timeline-edit-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onPatientClick(appointment.patient?.id);
                }}
                aria-label="Edit Appointment"
              >
                <FilePenLine size={20} />
                <span>Edit</span>
              </button>
              <button 
                className="timeline-action-button timeline-delete-button"
                onClick={(e) => e.stopPropagation()}
                aria-label="Delete Appointment"
              >
                <Trash2 size={20} />
                <span>Delete</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Timeline = ({ selectedDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appointments = useSelector((state) => state.doctor.appointments);
  const status = useSelector((state) => state.doctor.status);
  const error = useSelector((state) => state.doctor.error);

  useEffect(() => {
    dispatch(fetchDoctorAppointmentsByDate(selectedDate));
  }, [selectedDate, dispatch]);

  const handlePatientClick = (patientId) => {
    dispatch(fetchPatientDetails(patientId)).then(() => {
      navigate(`/doctors/chat/${patientId}`);
    });
  };

  if (status === 'loading') {
    return <div className="timeline-loading">Loading appointments...</div>;
  }

  if (error) {
    const errorMessage = typeof error === 'string' ? error : error.detail || 'An error occurred';
    return <div className="timeline-error">Error: {errorMessage}</div>;
  }

  return (
    <div className="timeline-container">
      <div className="timeline-header">
        <h2>Latest Patients Data</h2>
        <button className='view-all-btn'>View All</button>
      </div>
      <div className='timeline-box'>

      <div className="timeline-table-header">
        <div className="timeline-col-date">Time</div>
        <div className="timeline-col-name">Name</div>
        <div className="timeline-col-age">Age</div>
        <div className="timeline-col-medical-issue">Medical Issue</div>
        <div className="timeline-col-country">Country</div>
        <div className="timeline-col-gender">Gender</div>
        <div className="timeline-col-settings">Actions</div>
      </div>

      <div className="timeline-list">
        {appointments.length === 0 ? (
          <div className="timeline-empty">
            No appointments scheduled for this date.
          </div>
        ) : (
          appointments.map((appointment, index) => (
            <TimelineItem
              key={appointment.id}
              appointment={appointment}
              onPatientClick={handlePatientClick}
              index={index}
            />
          ))
        )}
      </div>
      </div>
    </div>
  );
};

export default Timeline;